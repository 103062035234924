// "use client"

// import React, { useState, useEffect } from "react"

// // Button component
// const Button = ({ children, onClick, className }) => (
//   <button
//     onClick={onClick}
//     className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${className}`}
//   >
//     {children}
//   </button>
// )

// // Card components
// const Card = ({ children, className }) => (
//   <div className={`bg-white shadow-md rounded-lg overflow-hidden ${className}`}>
//     {children}
//   </div>
// )

// const CardHeader = ({ children }) => (
//   <div className="px-6 py-4 border-b border-gray-200">{children}</div>
// )

// const CardTitle = ({ children }) => (
//   <h2 className="text-xl font-semibold">{children}</h2>
// )

// const CardContent = ({ children }) => (
//   <div className="px-6 py-4">{children}</div>
// )

// // FileText icon component
// const FileText = () => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//     stroke="currentColor"
//     strokeWidth="2"
//     strokeLinecap="round"
//     strokeLinejoin="round"
//   >
//     <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
//     <polyline points="14 2 14 8 20 8"></polyline>
//     <line x1="16" y1="13" x2="8" y2="13"></line>
//     <line x1="16" y1="17" x2="8" y2="17"></line>
//     <polyline points="10 9 9 9 8 9"></polyline>
//   </svg>
// )

// const SyllabusRenderer = () => {
//   const [syllabusItems, setSyllabusItems] = useState([])
//   const [isLoading, setIsLoading] = useState(true)
//   const [error, setError] = useState(null)

//   useEffect(() => {
//     const fetchSyllabus = async () => {
//       try {
//         const response = await fetch("https://nec.geoneer.com.np/api/exam/AMIN-PSC/syllabus/")
//         if (!response.ok) {
//           throw new Error("Failed to fetch syllabus data")
//         }
//         const data = await response.json()
//         setSyllabusItems(data)
//       } catch (err) {
//         setError("An error occurred while fetching the syllabus data.")
//       } finally {
//         setIsLoading(false)
//       }
//     }

//     fetchSyllabus()
//   }, [])

//   if (isLoading) {
//     return <div className="flex justify-center items-center h-screen">Loading syllabus data...</div>
//   }

//   if (error) {
//     return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>
//   }

//   return (
//     <div className="container mx-auto px-4 py-8">
//       <h1 className="text-3xl font-bold mb-6 text-center">Syllabus Items</h1>
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//         {syllabusItems.map((item) => (
//           <Card key={item.id} className="hover:shadow-lg transition-shadow duration-300">
//             <CardHeader>
//               <CardTitle>{item.name}</CardTitle>
//             </CardHeader>
//             <CardContent>
//               <p className="text-sm text-gray-600 mb-4">{item.place}</p>
//               <p className="text-sm text-gray-600 mb-4">Course: {item.course}</p>
//               <Button onClick={() => window.open('https://nec.geoneer.com.np/${item.file}', "_self")} className="w-full flex items-center justify-center">
//                 <FileText /> <span className="ml-2">View syllabus</span>
//               </Button>
//             </CardContent>
//           </Card>
//         ))}
//       </div>
//     </div>
//   )
// }

// export default SyllabusRenderer



import { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom"



const TableHeader = () => (
  <thead className="bg-gray-100">
    <tr className="text-sm">
      <th className="px-4 py-3 border">क्र.सं.</th>
      <th className="px-4 py-3 border">सेवा</th>
      <th className="px-4 py-3 border">श्रेणी/तह</th>
      <th className="px-4 py-3 border">समूह</th>
      {/*<th className="px-4 py-3 border">मिति</th>*/}
      <th className="px-4 py-3 border">उप समूह</th>
      <th className="px-4 py-3 border">पद</th>
      <th className="px-4 py-3 border">निकाय</th>
      <th className="px-4 py-3 border">प्रथम पत्र</th>
      <th className="px-4 py-3 border">द्वितीय पत्र</th>
  {/*    <th className="px-4 py-3 border">तृतीय पत्र</th>
      <th className="px-4 py-3 border">खुला/समा. प्र.</th>*/}
    </tr>
  </thead>
);

const TableRow = ({ item, index }) => (
  <tr className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
    <td className="px-4 py-3 border text-center">{index + 1}</td>
    <td className="px-4 py-3 border">{item.seva}</td>
    <td className="px-4 py-3 border">{item.grade_level}</td>
    <td className="px-4 py-3 border">{item.group}</td>
    {/*<td className="px-4 py-3 border">{item.date ? new Date(item.date).toLocaleDateString() : "N/A"}</td>*/}
    <td className="px-4 py-3 border">{item.sub_group}</td>
    <td className="px-4 py-3 border">{item.position}</td>
    <td className="px-4 py-3 border">{item.service}</td>
    <td className="px-4 py-3 border">
      {item.first_paper ? (
        <a
          href={`https://nec.geoneer.com.np${item.first_paper}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          Download
        </a>
      ) : (
        "N/A"
      )}
    </td>
    <td className="px-4 py-3 border">
      {item.second_paper ? (
        <a
          href={`https://nec.geoneer.com.np${item.second_paper}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          Download
        </a>
      ) : (
        "N/A"
      )}
    </td>
{/*    <td className="px-4 py-3 border">
      {item.third_paper ? (
        <a
          href={`https://nec.geoneer.com.np${item.third_paper}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          डाउनलोड
        </a>
      ) : (
        "N/A"
      )}
    </td>*/}
    {/*<td className="px-4 py-3 border">{item.open_internal || "N/A"}</td>*/}
  </tr>
);

const SyllabusRenderer = () => {
  const [syllabusItems, setSyllabusItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [courseCode, setCourseCode] = useState(null)
 

  // const history = useHistory()

  useEffect(() => {
    const storedUserDetails = localStorage.getItem("userDetails")
    if (storedUserDetails) {
      const parsedUserDetails = JSON.parse(storedUserDetails)
      setCourseCode(parsedUserDetails?.course?.courseCode)
    }
  }, [])

  // useEffect(() => {
  //   const fetchSyllabus = async () => {
  //     try {
  //       const response = await fetch(`https://nec.geoneer.com.np/api/exam/${courseCode}/syllabus/`);
  //         if (!response.ok) {
  //           throw new Error("Failed to fetch syllabus data");
  //         }
  //         const data = await response.json();
  //         setSyllabusItems(data);
  //       } catch (err) {
  //         setError("An error occurred while fetching the syllabus data.");
  //       } finally {
  //         setIsLoading(false);
  //       }
  //   };

  //   fetchSyllabus();
  // }, [courseCode]);

  const fetchSyllabus = async (courseCode) => {
    if (!courseCode) return; // Ensure we have a courseCode before fetching

    setIsLoading(true);
    try {
      const response = await fetch(`https://nec.geoneer.com.np/api/exam/${courseCode}/syllabus/`);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setSyllabusItems(data);
    } catch (err) {
      console.error("Fetching error:", err.message);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSyllabus(courseCode);
  }, [courseCode]);


  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">Loading syllabus data...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 overflow-x-auto">
            <br>
        </br>

        <br></br>


      <table className="min-w-full bg-white border border-gray-300">
        <TableHeader />
        <tbody>
          {syllabusItems.map((item, index) => (
            <TableRow key={item.id} item={item} index={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SyllabusRenderer;
