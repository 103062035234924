import { useState, useEffect } from "react"

const useScreenshotPrevention = () => {
  const [isHidden, setIsHidden] = useState(false)

  useEffect(() => {
    let hidden, visibilityChange

    if (typeof document.hidden !== "undefined") {
      hidden = "hidden"
      visibilityChange = "visibilitychange"
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden"
      visibilityChange = "msvisibilitychange"
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden"
      visibilityChange = "webkitvisibilitychange"
    }

    const handleVisibilityChange = () => {
      setIsHidden(document[hidden])
    }

    document.addEventListener(visibilityChange, handleVisibilityChange, false)

    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange)
    }
  }, [])

  return isHidden
}

export default useScreenshotPrevention

