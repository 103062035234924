import { useState, useEffect, useContext } from "react"
import axios from "axios"
import { XCircle, AlertTriangle, CheckCircle } from "lucide-react"
import AuthContext from "../../context/AuthContext"

const ReportQuestion = ({ questionId, isOpen, onClose }) => {
  const [reasons, setReasons] = useState([])
  const [selectedReason, setSelectedReason] = useState("")
  const [customReason, setCustomReason] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const { authTokens } = useContext(AuthContext)

  useEffect(() => {
    axios
      .get("https://nec.geoneer.com.np/api/exam/reporting/reasons/")
      .then((response) => setReasons(response.data))
      .catch((err) => console.error("Error fetching reasons:", err))
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError(null)
    setSuccess(null)

    if (!selectedReason && !customReason.trim()) {
      setError("Please select a reason or provide custom feedback.")
      setLoading(false)
      return
    }

    try {
      await axios.post(
        "https://nec.geoneer.com.np/api/exam/reports/submit/",
        {
          question: questionId,
          reason: selectedReason || null,
          custom_reason: customReason || null,
        },
        { headers: { Authorization: `Bearer ${authTokens?.access}` } },
      )

      setSuccess("Report submitted successfully!")
      setSelectedReason("")
      setCustomReason("")
      setTimeout(() => {
        onClose()
      }, 2000)
    } catch (err) {
      setError(err.response?.data?.detail || "Failed to submit report. Try again.")
    } finally {
      setLoading(false)
    }
  }

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-4 flex items-center">
          <AlertTriangle className="w-5 h-5 mr-2 text-yellow-500" />
          Report Question
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {error && (
            <div className="flex items-center p-3 text-sm text-red-800 rounded-lg bg-red-50">
              <XCircle className="w-5 h-5 mr-2" />
              {error}
            </div>
          )}
          {success && (
            <div className="flex items-center p-3 text-sm text-green-800 rounded-lg bg-green-50">
              <CheckCircle className="w-5 h-5 mr-2" />
              {success}
            </div>
          )}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Select a Reason:</label>
            <select
              value={selectedReason}
              onChange={(e) => setSelectedReason(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="">-- Choose a Reason --</option>
              {reasons.map((reason) => (
                <option key={reason.id} value={reason.id}>
                  {reason.reason}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Or provide a custom reason:</label>
            <textarea
              value={customReason}
              onChange={(e) => setCustomReason(e.target.value)}
              className="w-full p-2 border rounded"
              rows="3"
              placeholder="Describe the issue..."
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-400"
            >
              {loading ? "Submitting..." : "Submit Report"}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ReportQuestion

