"use client"

import { useState, useEffect } from "react"

const styles = {
  container: `min-h-screen bg-[#f8faf8] font-sans`,
  wrapper: `container mx-auto px-4 py-8`,
  header: `mb-8`,
  logoContainer: `flex justify-center mb-6`,
  logo: `h-16 md:h-20`,
  selectWrapper: `w-full max-w-2xl mx-auto bg-white rounded-lg shadow-md p-4`,
  select: `w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2e7d32]`,
  tableContainer: `bg-white rounded-lg shadow-lg overflow-hidden`,
  tableScroll: `overflow-x-auto`,
  table: `w-full border-collapse`,
  tableHeader: `bg-[#f8faf8]`,
  headerCell: `px-4 py-3 border border-[#e5ebe5] text-sm font-medium`,
  row: `hover:bg-[#f0f7f0] transition-colors duration-150`,
  cell: `px-4 py-3 border border-[#e5ebe5]`,
  centerCell: `px-4 py-3 border border-[#e5ebe5] text-center`,
  downloadButton: `inline-flex items-center justify-center px-3 py-1 text-sm font-medium text-white bg-[#2e7d32] rounded-md hover:bg-[#1b5e20] transition-colors`,
  naText: `text-gray-500`,
  loadingContainer: `flex justify-center items-center h-[200px]`,
  errorContainer: `flex justify-center items-center h-[50vh]`,
  errorMessage: `text-red-500 bg-red-50 px-4 py-2 rounded-md border border-red-200`,
  emptyMessage: `text-center py-8 text-gray-600`,
  initialMessage: `flex flex-col items-center justify-center h-[200px] text-gray-600 space-y-2`,
}

const TableHeader = () => (
  <thead className={styles.tableHeader}>
    <tr>
      <th className={styles.headerCell}>क्र.सं.</th>
      <th className={styles.headerCell}>सेवा</th>
      <th className={styles.headerCell}>श्रेणी/तह</th>
      <th className={styles.headerCell}>समूह</th>
      <th className={styles.headerCell}>उप समूह</th>
      <th className={styles.headerCell}>पद</th>
      <th className={styles.headerCell}>निकाय</th>
      <th className={styles.headerCell}>प्रथम पत्र</th>
      <th className={styles.headerCell}>द्वितीय पत्र</th>
    </tr>
  </thead>
)

const TableRow = ({ item, index }) => (
  <tr className={`${styles.row} ${index % 2 === 0 ? "bg-white" : "bg-[#f8faf8]"}`}>
    <td className={styles.centerCell}>{index + 1}</td>
    <td className={styles.cell}>{item.seva}</td>
    <td className={styles.cell}>{item.grade_level}</td>
    <td className={styles.cell}>{item.group}</td>
    <td className={styles.cell}>{item.sub_group}</td>
    <td className={styles.cell}>{item.position}</td>
    <td className={styles.cell}>{item.service}</td>
    <td className={styles.centerCell}>
      {item.first_paper ? (
        <a
          href={`https://nec.geoneer.com.np${item.first_paper}`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.downloadButton}
        >
          Download
        </a>
      ) : (
        <span className={styles.naText}>N/A</span>
      )}
    </td>
    <td className={styles.centerCell}>
      {item.second_paper ? (
        <a
          href={`https://nec.geoneer.com.np${item.second_paper}`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.downloadButton}
        >
          Download
        </a>
      ) : (
        <span className={styles.naText}>N/A</span>
      )}
    </td>
  </tr>
)

const SyllabusRenderer = () => {
  const [syllabusItems, setSyllabusItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [courses, setCourses] = useState([])
  const [selectedCourse, setSelectedCourse] = useState("")

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch("https://nec.geoneer.com.np/api/accounts/courses/")
        if (!response.ok) {
          throw new Error("Failed to fetch courses")
        }
        const data = await response.json()
        setCourses(data)
      } catch (err) {
        console.error("Error fetching courses:", err)
        setError("An error occurred while fetching the courses.")
      }
    }

    fetchCourses()
  }, [])

  const fetchSyllabus = async (courseCode) => {
    if (!courseCode) return

    setIsLoading(true)
    try {
      const response = await fetch(`https://nec.geoneer.com.np/api/exam/${courseCode}/syllabus/`)
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`)
      }
      const data = await response.json()
      setSyllabusItems(data)
    } catch (err) {
      console.error("Fetching error:", err.message)
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCourseChange = (event) => {
    const value = event.target.value
    setSelectedCourse(value)
    fetchSyllabus(value)
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>{error}</div>
      </div>
    )
  }

  const renderContent = () => {
    if (!selectedCourse) {
      return (
        <div className={styles.initialMessage}>
          {/* <svg
            className="w-12 h-12 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg> */}
          <p className="text-lg">Please select a course to view the syllabus</p>
        </div>
      )
    }

    if (isLoading) {
      return (
        <div className={styles.loadingContainer}>
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#2e7d32]"></div>
        </div>
      )
    }

    if (syllabusItems.length > 0) {
      return (
        <div className={styles.tableContainer}>
          <div className={styles.tableScroll}>
            <table className={styles.table}>
              <TableHeader />
              <tbody>
                {syllabusItems.map((item, index) => (
                  <TableRow key={item.id} item={item} index={index} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )
    }

    return <div className={styles.emptyMessage}>No syllabus data available for the selected course.</div>
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          {/* <div className={styles.logoContainer}>
            <img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/logo-UwkZHxEyegqqTnyRnsYHxnQctx0Z1b.png"
              alt="Examneer Logo"
              className={styles.logo}
            />
          </div> */}
          <br></br>
          <br></br>
          <br></br>
          <div className={styles.selectWrapper}>
            <select className={styles.select} value={selectedCourse} onChange={handleCourseChange}>
              <option value="">Select a course</option>
              {courses.map((course) => (
                <option key={course.id} value={course.courseCode}>
                  {course.courseName}
                </option>
              ))}
            </select>
          </div>
        </div>

        {renderContent()}
      </div>
    </div>
  )
}

export default SyllabusRenderer

