// import React, { useState, useEffect } from 'react';
// import './ChapterWiseSubjectiveExam.css'; // Import custom styles
// import { useLocation } from 'react-router-dom';

// const ChapterWiseSubjectiveExam = () => {
//   const [questions, setQuestions] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const location = useLocation();
//   const chapterCode = location.state?.id;

//   useEffect(() => {
//     const fetchQuestions = async () => {
//       try {
//         const response = await fetch(`https://nec.geoneer.com.np/api/exam/subjective-weekly-tests/${chapterCode}/questions/`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch questions');
//         }
//         const data = await response.json();
//         setQuestions(data);
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchQuestions();
//   }, [chapterCode]);

//   if (loading) return <div className="loading">Loading questions...</div>;
//   if (error) return <div className="error">Error: {error}</div>;

//   return (
//     <div className="exam-container">
//       <h1 className="title">Subjective Questions</h1>
//       {questions.length > 0 ? (
//         questions.map((item, index) => (
//           <div key={item.id} className="question-card">
//             <div className="question-content">
//               <strong>{index + 1}. </strong>
//               <span dangerouslySetInnerHTML={{ __html: item.question_text }}></span>
//             </div>
//             {item.hints && item.hints.toLowerCase() !== 'na' && (
//               <div
//                 className="hints"
//                 dangerouslySetInnerHTML={{ __html: item.hints }}
//               ></div>
//             )}
//             {/* Render weightage if it's available */}
//             {item.weightage && (
//               <p className="weightage">Weightage: {item.weightage}</p>
//             )}
//           </div>
//         ))
//       ) : (
//         <div className="no-questions">No questions available</div>
//       )}
//     </div>
//   );
// };

// export default ChapterWiseSubjectiveExam;



// import React, { useState, useEffect,useContext} from 'react';
// import './ChapterWiseSubjectiveExam.css';
// import { useLocation } from 'react-router-dom';
// import AuthContext from '../../context/AuthContext';


// const ChapterWiseSubjectiveExam = () => {
//   const [questions, setQuestions] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [file, setFile] = useState(null); // Selected file
//   const [uploadStatus, setUploadStatus] = useState(null); // Upload status
//   const location = useLocation();
//   const chapterCode = location.state?.id;
//   const { user } = useContext(AuthContext);


//   useEffect(() => {
//     const fetchQuestions = async () => {
//       try {
//         const response = await fetch(`https://nec.geoneer.com.np/api/exam/subjective-weekly-tests/${chapterCode}/questions/`);
//         if (!response.ok) throw new Error('Failed to fetch questions');
//         const data = await response.json();
//         setQuestions(data);
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchQuestions();
//   }, [chapterCode]);

//   const handleFileChange = (event) => {
//     const selectedFile = event.target.files[0];
//     if (selectedFile?.type === 'application/pdf') {
//       setFile(selectedFile);
//     } else {
//       alert('Please select a valid PDF file.');
//     }
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!file) {
//       setUploadStatus('No file selected');
//       return;
//     }

//     const testId = chapterCode;
//     const userId = 1;

//     if (!testId || !userId) {
//       setUploadStatus('Test ID or User ID missing.');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('pdf_answer', file);

//     try {
//       const response = await fetch(`https://nec.geoneer.com.np/api/exam/upload_answer/${testId}/${user.user_id}/`, {
//         method: 'POST',
//         body: formData,
       
//       });

//       if (!response.ok) throw new Error('Failed to upload answer sheet');
//       setUploadStatus('Answer sheet uploaded successfully');
//       setFile(null);
//     } catch (err) {
//       setUploadStatus(`Error: ${err.message}`);
//     }
//   };

//   if (loading) return <div className="loading">Loading questions...</div>;
//   if (error) return <div className="error">Error: {error}</div>;

//   return (
//     <div className="exam-container">
//       <h1 className="title">Subjective Questions</h1>

//       {questions.length > 0 ? (
//         questions.map((item, index) => (
//           <div key={item.id} className="question-card">
//             <div className="question-content">
//               <strong>{index + 1}. </strong>
//               <span dangerouslySetInnerHTML={{ __html: item.question_text }}></span>
//             </div>
//             {item.hints && item.hints.toLowerCase() !== 'na' && (
//               <div className="hints" dangerouslySetInnerHTML={{ __html: item.hints }}></div>
//             )}
//             {item.weightage && <p className="weightage">Weightage: {item.weightage}</p>}
//           </div>
//         ))
//       ) : (
//         <div className="no-questions">No questions available</div>
//       )}

//       {/* File upload section */}
//       <form onSubmit={handleSubmit} className="upload-form">
//         <input
//           type="file"
//           accept="application/pdf"
//           onChange={handleFileChange}
//           className="file-input"
//         />
//         <button type="submit" className="upload-button">Upload Answer Sheet</button>
//       </form>

//       {/* Upload status */}
//       {uploadStatus && <div className="upload-status">{uploadStatus}</div>}
//     </div>
//   );
// };

// export default ChapterWiseSubjectiveExam;


import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { FileText, Upload, AlertCircle, CheckCircle, Loader2 } from 'lucide-react';

const ChapterWiseSubjectiveExam = () => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const location = useLocation();
  const chapterCode = location.state?.id;
  const { user } = useContext(AuthContext);
  

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(`https://nec.geoneer.com.np/api/exam/subjective-weekly-tests/${chapterCode}/questions/`);
        if (!response.ok) throw new Error('Failed to fetch questions');
        const data = await response.json();
        setQuestions(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [chapterCode]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    validateAndSetFile(selectedFile);
  };

  const validateAndSetFile = (selectedFile) => {
    if (selectedFile?.type === 'application/pdf') {
      if (selectedFile.size > 150 * 1024 * 1024) { // 10MB limit
        setUploadStatus({
          type: 'error',
          message: 'File size should be less than 10MB'
        });
        return;
      }
      setFile(selectedFile);
      setUploadStatus({
        type: 'success',
        message: 'File selected successfully'
      });
    } else {
      setUploadStatus({
        type: 'error',
        message: 'Please select a valid PDF file'
      });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    validateAndSetFile(droppedFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      setUploadStatus({
        type: 'error',
        message: 'Please select a file to upload'
      });
      return;
    }

    const testId = chapterCode;
    const userId = user?.user_id;

    if (!testId || !userId) {
      setUploadStatus({
        type: 'error',
        message: 'Missing required information. Please try again.'
      });
      return;
    }

    const formData = new FormData();
    formData.append('pdf_answer', file);

    try {
      setUploadStatus({
        type: 'loading',
        message: 'Uploading your answer sheet...'
      });

      const response = await fetch(
        `https://nec.geoneer.com.np/api/exam/upload_answer/${testId}/${userId}/`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (!response.ok) throw new Error('Failed to upload answer sheet');
      
      setUploadStatus({
        type: 'success',
        message: 'Answer sheet uploaded successfully!'
      });
      setFile(null);
    } catch (err) {
      setUploadStatus({
        type: 'error',
        message: `Upload failed: ${err.message}`
      });
    }
  };

  const StatusMessage = ({ status }) => {
    if (!status) return null;

    const statusStyles = {
      success: "bg-green-50 text-green-700 border-green-200",
      error: "bg-red-50 text-red-700 border-red-200",
      loading: "bg-blue-50 text-blue-700 border-blue-200"
    };

    const StatusIcon = () => {
      switch (status.type) {
        case 'success':
          return <CheckCircle className="w-5 h-5" />;
        case 'error':
          return <AlertCircle className="w-5 h-5" />;
        case 'loading':
          return <Loader2 className="w-5 h-5 animate-spin" />;
        default:
          return null;
      }
    };

    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        className={`flex items-center gap-2 p-4 rounded-lg border ${statusStyles[status.type]}`}
      >
        <StatusIcon />
        <span>{status.message}</span>
      </motion.div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex items-center gap-2 text-green-600">
          <Loader2 className="w-6 h-6 animate-spin" />
          <span>Loading questions...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex items-center gap-2 text-red-600 bg-red-50 p-4 rounded-lg">
          <AlertCircle className="w-6 h-6" />
          <span>Error: {error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-3xl font-bold text-gray-900 text-center mb-8">
            Subjective Questions
          </h1>

          {/* Questions Section */}
          <div className="space-y-6 mb-12">
            {questions.length > 0 ? (
              questions.map((item, index) => (
                <motion.div
                  key={item.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-white rounded-xl shadow-sm border border-gray-200 p-6 hover:shadow-md transition-shadow"
                >
                  <div className="flex gap-4">
                    <span className="text-green-600 font-bold">{index + 1}.</span>
                    <div className="flex-1">
                      <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: item.question_text }}></div>
                      
                      {item.hints && item.hints.toLowerCase() !== 'na' && (
                        <div className="mt-4 bg-yellow-50 border border-yellow-100 rounded-lg p-4">
                          <p className="text-sm font-medium text-yellow-800 mb-1">Hints:</p>
                          <div className="text-yellow-700 text-sm" dangerouslySetInnerHTML={{ __html: item.hints }}></div>
                        </div>
                      )}
                      
                      {item.weightage && (
                        <div className="mt-4 inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
                          Weightage: {item.weightage}
                        </div>
                      )}
                    </div>
                  </div>
                </motion.div>
              ))
            ) : (
              <div className="text-center text-gray-500 py-12">No questions available</div>
            )}
          </div>

          {/* Upload Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-white rounded-xl shadow-lg border border-gray-200 p-8"
          >
            <h2 className="text-xl font-semibold text-gray-900 mb-6">
              Submit Your Answer Sheet
            </h2>

            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Drag and Drop Zone */}
              <div
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                className={`relative border-2 border-dashed rounded-xl p-8 text-center ${
                  isDragging
                    ? 'border-green-500 bg-green-50'
                    : 'border-gray-300 hover:border-gray-400'
                }`}
              >
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handleFileChange}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                />
                <div className="space-y-2">
                  <div className="flex justify-center">
                    <FileText className={`w-12 h-12 ${file ? 'text-green-500' : 'text-gray-400'}`} />
                  </div>
                  <div className="text-sm text-gray-600">
                    {file ? (
                      <span className="text-green-600 font-medium">{file.name}</span>
                    ) : (
                      <>
                        <span className="text-gray-900 font-medium">Click to upload</span> or drag and drop
                        <br />
                        PDF file (max 150MB)
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* Status Message */}
              <AnimatePresence mode="wait">
                {uploadStatus && <StatusMessage status={uploadStatus} />}
              </AnimatePresence>

              {/* Submit Button */}
              <motion.button
                type="submit"
                disabled={!file || uploadStatus?.type === 'loading'}
                className={`w-full flex items-center justify-center gap-2 px-4 py-3 border border-transparent rounded-lg text-white text-sm font-medium
                  ${
                    !file || uploadStatus?.type === 'loading'
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                  }
                `}
                whileHover={{ scale: file && uploadStatus?.type !== 'loading' ? 1.02 : 1 }}
                whileTap={{ scale: file && uploadStatus?.type !== 'loading' ? 0.98 : 1 }}
              >
                {uploadStatus?.type === 'loading' ? (
                  <>
                    <Loader2 className="w-4 h-4 animate-spin" />
                    Uploading...
                  </>
                ) : (
                  <>
                    <Upload className="w-4 h-4" />
                    Upload Answer Sheet
                  </>
                )}
              </motion.button>
            </form>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default ChapterWiseSubjectiveExam;




