// import React, { useState, useEffect, useRef, useContext } from 'react';
// import axios from 'axios';
// import '../../components/quiz.css';
// import Swal from 'sweetalert2';
// import parse from 'html-react-parser';
// import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import AuthContext from '../../context/AuthContext';

// const WeeklyExam = () => {
//   const [error, setError] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [items, setItems] = useState([]);
//   const [selectedAnswers, setSelectedAnswers] = useState({});
//   const questionRef = useRef(null);
//   const [showResultButton, setShowResultButton] = useState(false);
//   const [optionStyle, setOptionStyle] = useState({});
//   const [disabledQuestions, setDisabledQuestions] = useState([]);
//   const location = useLocation();
//   const id = location.state?.id;
//   const testname = location.state?.testname;
//   const { authTokens, user } = useContext(AuthContext);
//   const [submissionResponse, setSubmissionResponse] = useState(null);
//     const [showResultDetails, setShowResultDetails] = useState(false);
//       let questionNumber = 1; 


//   // const calculateScore = () => {
//   //   const correctAnswers = items.filter(
//   //     item => selectedAnswers[item.id] === item.correctOpt.toString()
//   //   ).length;
//   //   return correctAnswers ;
//   // };



//   useEffect(() => {
//     // Add viewport meta tag to prevent zooming/scaling
//     const viewport = document.querySelector('meta[name=viewport]');
//     if (!viewport) {
//       const meta = document.createElement('meta');
//       meta.name = 'viewport';
//       meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
//       document.head.appendChild(meta);
//     }
//   }, []);

//   const countCorrectAnswers = () =>
//     Object.entries(selectedAnswers).filter(([id, answer]) =>
//       items.find(item => item.id.toString() === id && item.correctOpt.toString() === answer)
//     ).length;

//   const countWrongAnswers = () =>
//     Object.entries(selectedAnswers).filter(([id, answer]) =>
//       items.find(item => item.id.toString() === id && item.correctOpt.toString() !== answer)
//     ).length;

//   const countUnattempted = () => items.length - Object.keys(selectedAnswers).length;

//   useEffect(() => {
//     axios
//       .get(`https://nec.geoneer.com.np/api/exam/weekly-tests/${id}/questions/`)
//       .then(response => {
//         setIsLoaded(true);
//         setItems(response.data);
//       })
//       .catch(error => {
//         setIsLoaded(true);
//         setError(error);
//       });
//   }, [id]);

//   useEffect(() => {
//     if (questionRef.current) {
//       const questionWidth = questionRef.current.getBoundingClientRect().width;
//       setOptionStyle({ width: `${questionWidth}px` });
//     }
//   }, [items]);


//     const mergedItems = items.reduce((acc, item) => {
//     if (!acc[item.id]) {
//       acc[item.id] = item;
//     } else {
//       acc[item.id] = { ...acc[item.id], ...item };
//     }
//     return acc;
//   }, {});

//   const handleOptionClick = (itemId, option) => {
//     if (!selectedAnswers[itemId] && !showResultButton) {
//       setSelectedAnswers(prevState => ({
//         ...prevState,
//         [itemId]: option.toString(),
//       }));
//     }
//   };

//   const handleSubmit = async () => {
//     setShowResultButton(true);



//     const correctCount = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
//       const item = mergedItemsArray[index];
//       if (selectedOption === item.correctOpt.toString()) {
//         acc++;
//       }
//       return acc;
//     }, 0);

//     const totalScore = attendedQuestionsCount;
//     const userScore = correctCount-0.2*(totalScore-correctCount);
//         setShowResultDetails(true);

//     Swal.fire({
//       title: 'Result',
//       text: `You answered ${correctCount} questions correctly! Your score is ${userScore}`,
//       icon: 'info',
//       timer: 36000,
 
//     });
 
//     const payload = {
//       weekly_test: id,
//       score: userScore,
//       total_score: String(items.length).padStart(3, '0'),
//       correct_count: countCorrectAnswers(),
//       wrong_count: countWrongAnswers(),
//       unattempted_count: countUnattempted(),
//       total_questions: items.length,
//       questions: items.map(item => ({
//         question: item.id,
//         user_selected_option: selectedAnswers[item.id] || null,
//         is_correct: item.correctOpt.toString() === selectedAnswers[item.id],
//       })),
//     };

//     axios
//       .post(`https://nec.geoneer.com.np/api/exam/user/tests/store/${user.user_id}/${id}/`,
//          payload, {
//         headers: {
//           Authorization: `Bearer ${authTokens?.access}`,
//         },
//       })
//       .then(response => {
//         setSubmissionResponse(response.data);
//         // Swal.fire({
//         //   title:'Submitted!', 'Your test has been submitted.',
//         //   toast: true,
//         // }
        
//         // );
//         // Swal.fire({
//         //   title: 'success',
//         //   text: 'Your test has been submitted.',
//         //   icon: 'error',
//         //   toast: true,
//         //   position: 'top-end',
//         //   showConfirmButton: true,
//         //   timer: 3000
//         // });

//         Swal.fire({
//           title: "Your test has been submitted.",
//           icon: "success",
//           toast: true,
//           timer: 6000,
//           position: "top-right",
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
      
        
//         setShowResultButton(false);
//       })
//       .catch(error => {
//         console.error(error);
//         Swal.fire({
//           title: 'Error!',
//           text: 'Your test has already been submitted.',
//           icon: 'error',
//           toast: true,
//           position: 'top-end',
//           showConfirmButton: false,
//           timer: 3000
//         });
//       });
//   };

//   // const getOptionClassName = (itemId, option) => {
//   //   if (selectedAnswers[itemId] === option.toString()) {
//   //     return showResultButton && selectedAnswers[itemId] !== items.find(item => item.id === itemId).correctOpt.toString()
//   //       ? 'option wrong'
//   //       : 'option selected';
//   //   }
//   //   return 'option';
//   // };


//   const getOptionClassName = (itemId, option) => {
//     const item = mergedItemsArray.find(item => item.id === itemId);
//     const selectedOption = selectedAnswers[itemId];
  
//     if (showResultButton) {
//       if (selectedOption === option.toString()) {
//         return selectedOption === item.correctOpt.toString()
//           ? 'option selected' // Correctly selected option
//           : 'option wrong';  // Incorrectly selected option
//       }
//       return option.toString() === item.correctOpt.toString()
//         ? 'option selected' // Highlight the correct option after submission
//         : 'option';         // Unselected options
//     }
  
//     // If not showing results yet, handle selection and disabled state
//     if (selectedOption === option.toString()) {
//       return 'option selected';
//     }
//     if (disabledQuestions.includes(itemId)) {
//       return 'option disabled';
//     }
//     return 'option'; // Default state
//   };
  


//     const mergedItemsArray = Object.values(mergedItems);

//       const attendedQuestionsCount = Object.keys(selectedAnswers).length;

//         const unattemptedQuestionsCount = mergedItemsArray.length - attendedQuestionsCount;



//   return (
//     <div className="quiz-container">


// <h6>Practice Questions for    <strong>"{testname}"</strong> </h6>
// <br />
//     <div className="attendance-count">
//       {attendedQuestionsCount} Questions Attended <br></br> {unattemptedQuestionsCount} Unattempted
//     </div>

//     {error && <div>Error: {error.message}</div>}
//     {!isLoaded && !error && <div>Loading...</div>}
//     {isLoaded && mergedItemsArray.length === 0 && <div>No items to display</div>}

//     {isLoaded && mergedItemsArray.length > 0 && (
//       <ul style={{ listStyleType: 'none', paddingLeft: '0', marginRight: '10px' }}>
//         {mergedItemsArray.map(item => (
//           <li key={item.id} className={selectedAnswers[item.id] ? '' : 'unattempted'}>
//             <br />
//             <h6>
//               <div ref={questionRef} style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//                 <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
//                   Q {questionNumber++}:
//                 </span>
//                 <span style={{ flex: 1 }}>
//                   {parse(item.question)}
//                 </span>
//               </div>
//             </h6>

//             <ul style={{ listStyleType: 'none', padding: '0', margin: '0', textAlign: 'left' }}>
//               <li
//                 className={getOptionClassName(item.id, "1")}
//                 onClick={() => handleOptionClick(item.id, "1")}
//                 style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//               >
//                 <span className="option-text">{parse(item.option1)}</span>
//               </li>
//               <li
//                 className={getOptionClassName(item.id, "2")}
//                 onClick={() => handleOptionClick(item.id, "2")}
//                 style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//               >
//                 <span className="option-text">{parse(item.option2)}</span>
//               </li>
//               <li
//                 className={getOptionClassName(item.id, "3")}
//                 onClick={() => handleOptionClick(item.id, "3")}
//                 style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//               >
//                 <span className="option-text">{parse(item.option3)}</span>
//               </li>
//               <li
//                 className={getOptionClassName(item.id, "4")}
//                 onClick={() => handleOptionClick(item.id, "4")}
//                 style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//               >
//                 <span className="option-text">{parse(item.option4)}</span>
//               </li>
//             </ul>
//             {showResultDetails && (
//               <div className="result-details">
//                 <p style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>Correct Option: {item.correctOpt}</p>
//                 <div className="result-details" style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//                   {/* <p>Explanation: {parse(item.explanation)}</p> */}
//                   <strong>Explanation:  </strong> {parse(item.explanation)}
//                 </div>
//               </div>
//             )}
//           </li>
//         ))}
//       </ul>
//     )}



      
//       <button  className="submit-button" onClick={handleSubmit} disabled={!Object.keys(selectedAnswers).length}>
//         Submit
//       </button>

//       {console.log(disabledQuestions)}
//       {console.log(submissionResponse)}
//       {console.log(setDisabledQuestions)}
//     </div>
//   );
// };

// export default WeeklyExam;



// import React, { useState, useEffect, useRef, useContext } from "react"
// import axios from "axios"
// import "../../components/quiz.css"
// import Swal from "sweetalert2"
// import parse from "html-react-parser"
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
// import AuthContext from "../../context/AuthContext"

// const WeeklyExam = () => {
//   const [error, setError] = useState(null)
//   const [isLoaded, setIsLoaded] = useState(false)
//   const [items, setItems] = useState([])
//   const [selectedAnswers, setSelectedAnswers] = useState({})
//   const questionRef = useRef(null)
//   const [showResultButton, setShowResultButton] = useState(false)
//   const [optionStyle, setOptionStyle] = useState({})
//   const [disabledQuestions, setDisabledQuestions] = useState([])
//   const location = useLocation()
//   const id = location.state?.id
//   const testname = location.state?.testname
//   const { authTokens, user,userDetails } = useContext(AuthContext)
//   const [submissionResponse, setSubmissionResponse] = useState(null)
//   const [showResultDetails, setShowResultDetails] = useState(false)
//   let questionNumber = 1

//   const isAGEorACiE = 1;

//   useEffect(() => {
//     const viewport = document.querySelector("meta[name=viewport]")
//     if (!viewport) {
//       const meta = document.createElement("meta")
//       meta.name = "viewport"
//       meta.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
//       document.head.appendChild(meta)
//     }
//   }, [])

//   const countCorrectAnswers = () =>
//     Object.entries(selectedAnswers).filter(([id, answer]) =>
//       items.find((item) => item.id.toString() === id && item.correctOpt.toString() === answer),
//     ).length

//   const countWrongAnswers = () =>
//     Object.entries(selectedAnswers).filter(([id, answer]) =>
//       items.find((item) => item.id.toString() === id && item.correctOpt.toString() !== answer),
//     ).length

//   const countUnattempted = () => items.length - Object.keys(selectedAnswers).length

//   useEffect(() => {
//     axios
//       .get(`https://nec.geoneer.com.np/api/exam/weekly-tests/${id}/questions/`)
//       .then((response) => {
//         setIsLoaded(true)
//         setItems(response.data)
//       })
//       .catch((error) => {
//         setIsLoaded(true)
//         setError(error)
//       })
//   }, [id])

//   useEffect(() => {
//     if (questionRef.current) {
//       const questionWidth = questionRef.current.getBoundingClientRect().width
//       setOptionStyle({ width: `${questionWidth}px` })
//     }
//   }, [questionRef]) //Corrected dependency

//   const handleOptionClick = (itemId, option) => {
//     if (!selectedAnswers[itemId] && !showResultButton) {
//       setSelectedAnswers((prevState) => ({
//         ...prevState,
//         [itemId]: option.toString(),
//       }))
//     }
//   }

//   const calculateScore = () => {
//     let score = 0
//     let correctCount = 0

//     items.forEach((item) => {
//       const selectedAnswer = selectedAnswers[item.id]
//       if (selectedAnswer === item.correctOpt.toString()) {
//         score += isAGEorACiE ? (item.weightage === 1 ? 1 : 2) : 1
//         correctCount++
//       }
//     })

//     return { score, correctCount }
//   }

//   const handleSubmit = async () => {
//     setShowResultButton(true)

//     const { score, correctCount } = calculateScore()
//     const totalScore = items.length

//     setShowResultDetails(true)

//     Swal.fire({
//       title: "Result",
//       text: `You answered ${correctCount} questions correctly! Your score is ${score}`,
//       icon: "info",
//       timer: 36000,
//     })

//     const payload = {
//       weekly_test: id,
//       score: score,
//       total_score: String(totalScore).padStart(3, "0"),
//       correct_count: correctCount,
//       wrong_count: countWrongAnswers(),
//       unattempted_count: countUnattempted(),
//       total_questions: items.length,
//       questions: items.map((item) => ({
//         question: item.id,
//         user_selected_option: selectedAnswers[item.id] || null,
//         is_correct: item.correctOpt.toString() === selectedAnswers[item.id],
//       })),
//     }

//     axios
//       .post(`https://nec.geoneer.com.np/api/exam/user/tests/store/${user.user_id}/${id}/`, payload, {
//         headers: {
//           Authorization: `Bearer ${authTokens?.access}`,
//         },
//       })
//       .then((response) => {
//         setSubmissionResponse(response.data)
//         Swal.fire({
//           title: "Your test has been submitted.",
//           icon: "success",
//           toast: true,
//           timer: 6000,
//           position: "top-right",
//           timerProgressBar: true,
//           showConfirmButton: false,
//         })
//         setShowResultButton(false)
//       })
//       .catch((error) => {
//         console.error(error)
//         Swal.fire({
//           title: "Error!",
//           text: "Your test has already been submitted.",
//           icon: "error",
//           toast: true,
//           position: "top-end",
//           showConfirmButton: false,
//           timer: 3000,
//         })
//       })
//   }

//   const getOptionClassName = (itemId, option) => {
//     const item = items.find((item) => item.id === itemId)
//     const selectedOption = selectedAnswers[itemId]

//     if (showResultButton) {
//       if (selectedOption === option.toString()) {
//         return selectedOption === item.correctOpt.toString() ? "option selected" : "option wrong"
//       }
//       return option.toString() === item.correctOpt.toString() ? "option selected" : "option"
//     }

//     if (selectedOption === option.toString()) {
//       return "option selected"
//     }
//     if (disabledQuestions.includes(itemId)) {
//       return "option disabled"
//     }
//     return "option"
//   }

//   const groupQuestions = (questions) => {
//     if (!isAGEorACiE) return { all: questions }

//     return {
//       groupA: questions.filter((q) => q.weightage === 1),
//       groupB: questions.filter((q) => q.weightage === 4),
//     }
//   }

//   const groupedQuestions = groupQuestions(items)

//   return (
//     <div className="quiz-container">
//       <h6>
//         Practice Questions for <strong>"{testname}"</strong>
//       </h6>
//       <br />
//       <div className="attendance-count">
//         {Object.keys(selectedAnswers).length} Questions Attended <br />
//         {items.length - Object.keys(selectedAnswers).length} Unattempted
//       </div>

//       {error && <div>Error: {error.message}</div>}
//       {!isLoaded && !error && <div>Loading...</div>}
//       {isLoaded && items.length === 0 && <div>No items to display</div>}

//       {isLoaded && items.length > 0 && (
//         <ul style={{ listStyleType: "none", paddingLeft: "0", marginRight: "10px" }}>
//           {isAGEorACiE && (
//             <>
//               <h3>Group A (1 Mark Questions)</h3>
//               {groupedQuestions.groupA.map(renderQuestion)}
//               <h3>Group B (2 Marks Questions)</h3>
//               {groupedQuestions.groupB.map(renderQuestion)}
//             </>
//           )}
//           {!isAGEorACiE && groupedQuestions.all.map(renderQuestion)}
//         </ul>
//       )}

//       <button className="submit-button" onClick={handleSubmit} disabled={!Object.keys(selectedAnswers).length}>
//         Submit
//       </button>
//     </div>
//   )

//   function renderQuestion(item) {
//     return (
//       <li key={item.id} className={selectedAnswers[item.id] ? "" : "unattempted"}>
//         <br />
//         <h6>
//           <div ref={questionRef} style={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}>
//             <span style={{ marginRight: "10px", whiteSpace: "nowrap" }}>Q {questionNumber++}:</span>
//             <span style={{ flex: 1 }}>{parse(item.question)}</span>
//           </div>
//         </h6>

//         <ul style={{ listStyleType: "none", padding: "0", margin: "0", textAlign: "left" }}>
//           {["1", "2", "3", "4"].map((optionNumber) => (
//             <li
//               key={optionNumber}
//               className={getOptionClassName(item.id, optionNumber)}
//               onClick={() => handleOptionClick(item.id, optionNumber)}
//               style={{ ...optionStyle, marginBottom: "5px", padding: "10px", cursor: "pointer" }}
//             >
//               <span className="option-text">{parse(item[`option${optionNumber}`])}</span>
//             </li>
//           ))}
//         </ul>
//         {showResultDetails && (
//           <div className="result-details">
//             <p style={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}>
//               Correct Option: {item.correctOpt}
//             </p>
//             <div className="result-details" style={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}>
//               <strong>Explanation: </strong> {parse(item.explanation)}
//             </div>
//           </div>
//         )}
//       </li>
//     )
//   }
// }

// export default WeeklyExam


// import React, { useState, useEffect, useRef, useContext } from "react"
// import axios from "axios"
// import "../../components/quiz.css"
// import Swal from "sweetalert2"
// import parse from "html-react-parser"
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
// import AuthContext from "../../context/AuthContext"

// const WeeklyExam = () => {
//   const [error, setError] = useState(null)
//   const [isLoaded, setIsLoaded] = useState(false)
//   const [items, setItems] = useState([])
//   const [selectedAnswers, setSelectedAnswers] = useState({})
//   const questionRef = useRef(null)
//   const [showResultButton, setShowResultButton] = useState(false)
//   const [optionStyle, setOptionStyle] = useState({})
//   const [disabledQuestions, setDisabledQuestions] = useState([])
//   const location = useLocation()
//   const id = location.state?.id
//   const testname = location.state?.testname
//   const { authTokens, user, userDetails } = useContext(AuthContext)
//   const [submissionResponse, setSubmissionResponse] = useState(null)
//   const [showResultDetails, setShowResultDetails] = useState(false)
//   let questionNumber = 1

//   const isAGEorACiE = userDetails?.course?.courseCode === "AGE" || userDetails?.course?.courseCode === "ACiE"

//   useEffect(() => {
//     const viewport = document.querySelector("meta[name=viewport]")
//     if (!viewport) {
//       const meta = document.createElement("meta")
//       meta.name = "viewport"
//       meta.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
//       document.head.appendChild(meta)
//     }
//   }, [])

//   const countCorrectAnswers = () =>
//     Object.entries(selectedAnswers).filter(([id, answer]) =>
//       items.find((item) => item.id.toString() === id && item.correctOpt.toString() === answer),
//     ).length

//   const countWrongAnswers = () =>
//     Object.entries(selectedAnswers).filter(([id, answer]) =>
//       items.find((item) => item.id.toString() === id && item.correctOpt.toString() !== answer),
//     ).length

//   const countUnattempted = () => items.length - Object.keys(selectedAnswers).length

//   useEffect(() => {
//     axios
//       .get(`https://nec.geoneer.com.np/api/exam/weekly-tests/${id}/questions/`)
//       .then((response) => {
//         setIsLoaded(true)
//         setItems(response.data)
//       })
//       .catch((error) => {
//         setIsLoaded(true)
//         setError(error)
//       })
//   }, [id])

//   useEffect(() => {
//     if (questionRef.current) {
//       const questionWidth = questionRef.current.getBoundingClientRect().width
//       setOptionStyle({ width: `${questionWidth}px` })
//     }
//   }, []) // Removed questionRef from dependencies

//   const handleOptionClick = (itemId, option) => {
//     if (!selectedAnswers[itemId] && !showResultButton) {
//       setSelectedAnswers((prevState) => ({
//         ...prevState,
//         [itemId]: option.toString(),
//       }))
//     }
//   }

//   const calculateScore = () => {
//     let score = 0
//     let correctCount = 0

//     items.forEach((item) => {
//       const selectedAnswer = selectedAnswers[item.id]
//       if (selectedAnswer === item.correctOpt.toString()) {
//         score += isAGEorACiE ? (item.weightage === 1 ? 1 : 2) : 1
//         correctCount++
//       }
//     })

//     return { score, correctCount }
//   }

//   const handleSubmit = async () => {
//     setShowResultButton(true)

//     const { score, correctCount } = calculateScore()
//     const totalScore = items.length

//     setShowResultDetails(true)

//     Swal.fire({
//       title: "Result",
//       text: `You answered ${correctCount} questions correctly! Your score is ${score}`,
//       icon: "info",
//       timer: 36000,
//     })

//     const payload = {
//       weekly_test: id,
//       score: score,
//       total_score: String(totalScore).padStart(3, "0"),
//       correct_count: correctCount,
//       wrong_count: countWrongAnswers(),
//       unattempted_count: countUnattempted(),
//       total_questions: items.length,
//       questions: items.map((item) => ({
//         question: item.id,
//         user_selected_option: selectedAnswers[item.id] || null,
//         is_correct: item.correctOpt.toString() === selectedAnswers[item.id],
//       })),
//     }

//     axios
//       .post(`https://nec.geoneer.com.np/api/exam/user/tests/store/${user.user_id}/${id}/`, payload, {
//         headers: {
//           Authorization: `Bearer ${authTokens?.access}`,
//         },
//       })
//       .then((response) => {
//         setSubmissionResponse(response.data)
//         Swal.fire({
//           title: "Your test has been submitted.",
//           icon: "success",
//           toast: true,
//           timer: 6000,
//           position: "top-right",
//           timerProgressBar: true,
//           showConfirmButton: false,
//         })
//         setShowResultButton(false)
//       })
//       .catch((error) => {
//         console.error(error)
//         Swal.fire({
//           title: "Error!",
//           text: "Your test has already been submitted.",
//           icon: "error",
//           toast: true,
//           position: "top-end",
//           showConfirmButton: false,
//           timer: 3000,
//         })
//       })
//   }

//   const getOptionClassName = (itemId, option) => {
//     const item = items.find((item) => item.id === itemId)
//     const selectedOption = selectedAnswers[itemId]

//     if (showResultButton) {
//       if (selectedOption === option.toString()) {
//         return selectedOption === item.correctOpt.toString() ? "option selected" : "option wrong"
//       }
//       return option.toString() === item.correctOpt.toString() ? "option selected" : "option"
//     }

//     if (selectedOption === option.toString()) {
//       return "option selected"
//     }
//     if (disabledQuestions.includes(itemId)) {
//       return "option disabled"
//     }
//     return "option"
//   }

//   const groupQuestions = (questions) => {
//     if (!isAGEorACiE) return { all: questions }

//     return {
//       groupA: questions.filter((q) => q.weightage === 1),
//       groupB: questions.filter((q) => q.weightage === 4),
//     }
//   }

//   const groupedQuestions = groupQuestions(items)

//   return (
//     <div className="quiz-container">
//       <h6>
//         Practice Questions for <strong>"{testname}"</strong>
//       </h6>
//       <br />
//       <div className="attendance-count">
//         {Object.keys(selectedAnswers).length} Questions Attended <br />
//         {items.length - Object.keys(selectedAnswers).length} Unattempted
//       </div>

//       {error && <div>Error: {error.message}</div>}
//       {!isLoaded && !error && <div>Loading...</div>}
//       {isLoaded && items.length === 0 && <div>No items to display</div>}

//       {isLoaded && items.length > 0 && (
//         <ul style={{ listStyleType: "none", paddingLeft: "0", marginRight: "10px" }}>
//           {isAGEorACiE && (
//             <>
//               <h3 style={{ fontWeight: "bold", textAlign: "center" }}>Group A (1 Mark Questions)</h3>
//               {groupedQuestions.groupA.map(renderQuestion)}
//               <h3 style={{ fontWeight: "bold", textAlign: "center" }}>Group B (2 Marks Questions)</h3>
//               {groupedQuestions.groupB.map(renderQuestion)}
//             </>
//           )}
//           {!isAGEorACiE && groupedQuestions.all.map(renderQuestion)}
//         </ul>
//       )}

//       <button className="submit-button" onClick={handleSubmit} disabled={!Object.keys(selectedAnswers).length}>
//         Submit
//       </button>
//     </div>
//   )

//   function renderQuestion(item) {
//     return (
//       <li key={item.id} className={selectedAnswers[item.id] ? "" : "unattempted"}>
//         <br />
//         <h6>
//           <div ref={questionRef} style={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}>
//             <span style={{ marginRight: "10px", whiteSpace: "nowrap" }}>Q {questionNumber++}:</span>
//             <span style={{ flex: 1 }}>{parse(item.question)}</span>
//           </div>
//         </h6>

//         <ul style={{ listStyleType: "none", padding: "0", margin: "0", textAlign: "left" }}>
//           {["1", "2", "3", "4"].map((optionNumber) => (
//             <li
//               key={optionNumber}
//               className={getOptionClassName(item.id, optionNumber)}
//               onClick={() => handleOptionClick(item.id, optionNumber)}
//               style={{ ...optionStyle, marginBottom: "5px", padding: "10px", cursor: "pointer" }}
//             >
//               <span className="option-text">{parse(item[`option${optionNumber}`])}</span>
//             </li>
//           ))}
//         </ul>
//         {showResultDetails && (
//           <div className="result-details">
//             <p style={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}>
//               Correct Option: {item.correctOpt}
//             </p>
//             <div className="result-details" style={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}>
//               <strong>Explanation: </strong> {parse(item.explanation)}
//             </div>
//           </div>
//         )}
//       </li>
//     )
//   }
// }

// export default WeeklyExam


import React, { useState, useEffect, useRef, useContext } from "react"
import axios from "axios"
import "../../components/quiz.css"
import Swal from "sweetalert2"
import parse from "html-react-parser"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import AuthContext from "../../context/AuthContext"
import ReportQuestion from "./ReportQuestion"
import { AlertTriangle } from "lucide-react"


const WeeklyExam = () => {
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [items, setItems] = useState([])
  const [selectedAnswers, setSelectedAnswers] = useState({})
  const questionRef = useRef(null)
  const [showResultButton, setShowResultButton] = useState(false)
  const [optionStyle, setOptionStyle] = useState({})
  const [disabledQuestions] = useState([])
  const location = useLocation()
  const id = location.state?.id
  const testname = location.state?.testname
  const { authTokens, user  } = useContext(AuthContext)
  const [submissionResponse, setSubmissionResponse] = useState(null)
  const [showResultDetails, setShowResultDetails] = useState(false)
  let questionNumber = 1
    const courseCode = location.state?.courseCode
  const [reportModalOpen, setReportModalOpen] = useState(false)
  const [reportQuestionId, setReportQuestionId] = useState(null)


  const isAGEorACiE = courseCode === "AGE" || courseCode === "ACiE" || false
  const isAMINPSC = courseCode === "AMIN-PSC" || courseCode === "PSC-S" 


  
  useEffect(() => {
    const viewport = document.querySelector("meta[name=viewport]")
    if (!viewport) {
      const meta = document.createElement("meta")
      meta.name = "viewport"
      meta.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      document.head.appendChild(meta)
    }
  }, [])

  // const countCorrectAnswers = () =>
  //   Object.entries(selectedAnswers).filter(([id, answer]) =>
  //     items.find((item) => item.id.toString() === id && item.correctOpt.toString() === answer),
  //   ).length

  // const countWrongAnswers = () =>
  //   Object.entries(selectedAnswers).filter(([id, answer]) =>
  //     items.find((item) => item.id.toString() === id && item.correctOpt.toString() !== answer),
  //   ).length

  const countUnattempted = () => items.length - Object.keys(selectedAnswers).length

  useEffect(() => {
    axios
      .get(`https://nec.geoneer.com.np/api/exam/weekly-tests/${id}/questions/`)
      .then((response) => {
        setIsLoaded(true)
        setItems(response.data)
      })
      .catch((error) => {
        setIsLoaded(true)
        setError(error)
      })
  }, [id])

  useEffect(() => {
    if (questionRef.current) {
      const questionWidth = questionRef.current.getBoundingClientRect().width
      setOptionStyle({ width: `${questionWidth}px` })
    }
  }, []) // Removed questionRef from dependencies

  const handleOptionClick = (itemId, option) => {
    if (!selectedAnswers[itemId] && !showResultButton) {
      setSelectedAnswers((prevState) => ({
        ...prevState,
        [itemId]: option.toString(),
      }))
    }
  }

  // const calculateScore = () => {
  //   let score = 0
  //   let correctCount = 0

  //   items.forEach((item) => {
  //     const selectedAnswer = selectedAnswers[item.id]
  //     if (selectedAnswer === item.correctOpt.toString()) {
  //       score += isAGEorACiE ? (item.weightage === 1 ? 1 : 2) : 1
  //       correctCount++
  //     }
  //   })

  //   return { score, correctCount }
  // }

const calculateScore = () => {
  let score = 0
  let correctCount = 0
  let wrongCount = 0

  items.forEach((item) => {
    const selectedAnswer = selectedAnswers[item.id]
    if (selectedAnswer === item.correctOpt.toString()) {
      if (isAGEorACiE) {
        score += item.weightage === 1 ? 1 : 2
      } else if (isAMINPSC) {
        score += 2
      } else {
        score += 1
      }
      correctCount++
    } else if (selectedAnswer !== undefined) {
      if (isAMINPSC) {
        score -= 0.2 * (item.weightage === 1 ? 1 : 2); // Deduct 20% of the weightage
      } 

     else  if (!isAGEorACiE ) {
        score -= 0.2 // Deduct 20% for each wrong answer only for non-AGE/ACiE and non-AMIN-PSC
      }
      wrongCount++
    }
  })

  score = Math.max(score, 0)
  return { score, correctCount, wrongCount }
}

const handleSubmit = async () => {
  setShowResultButton(true)

  const { score, correctCount, wrongCount } = calculateScore()
  const totalScore = isAMINPSC ? items.length * 2 : items.length

  setShowResultDetails(true)

  Swal.fire({
    title: "Result",
    html: `
      <p>You answered ${correctCount} questions correctly!</p>
      <p>You answered ${wrongCount} questions incorrectly.</p>
      <p>Your score is ${score.toFixed(2)} out of ${totalScore}</p>
    `,
    icon: "info",
    // timer: 3600000,
    confirmButtonText: "Close",
    allowOutsideClick: false,
    allowEscapeKey: false,
  })

  const payload = {
    weekly_test: id,
    score: score,
    total_score: totalScore.toFixed(2),
    correct_count: correctCount,
    wrong_count: wrongCount,
    unattempted_count: countUnattempted(),
    total_questions: items.length,
    questions: items.map((item) => ({
      question: item.id,
      user_selected_option: selectedAnswers[item.id] || null,
      is_correct: item.correctOpt.toString() === selectedAnswers[item.id],
    })),
  }

  setTimeout(async () => {
  try {
    const response = await axios.post(
      `https://nec.geoneer.com.np/api/exam/user/tests/store/${user.user_id}/${id}/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${authTokens?.access}`,
        },
      },
    )
    setSubmissionResponse(response.data)
    Swal.fire({
      title: "Your test has been submitted.",
      icon: "success",
      toast: true,
      timer: 6000,
      position: "top-right",
      timerProgressBar: true,
      showConfirmButton: false,
    })
    setShowResultButton(false)
  } catch (error) {
    console.error(error)
    console.log(submissionResponse)
    Swal.fire({
      title: "Error!",
      text: "Your test has already been submitted.",
      icon: "error",
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
    })
  }
},
2000); // Delay second popup by 1 minute
};



  const getOptionClassName = (itemId, option) => {
    const item = items.find((item) => item.id === itemId)
    const selectedOption = selectedAnswers[itemId]

    if (showResultButton) {
      if (selectedOption === option.toString()) {
        return selectedOption === item.correctOpt.toString() ? "option selected" : "option wrong"
      }
      return option.toString() === item.correctOpt.toString() ? "option selected" : "option"
    }

    if (selectedOption === option.toString()) {
      return "option selected"
    }
    if (disabledQuestions.includes(itemId)) {
      return "option disabled"
    }
    return "option"
  }

  const groupQuestions = (questions) => {
    if (!isAGEorACiE) return { all: questions }

    return {
      groupA: questions.filter((q) => q.weightage === 1),
      groupB: questions.filter((q) => q.weightage === 4),
    }
  }

  const groupedQuestions = groupQuestions(items)

  const handleReportQuestion = (questionId) => {
    setReportQuestionId(questionId)
    setReportModalOpen(true)
  }

  return (
    <div className="quiz-container">
      <h6>
       <strong>{testname}</strong>
      </h6>
      <br />
      <div className="attendance-count">
        {Object.keys(selectedAnswers).length} Questions Attended <br />
        {items.length - Object.keys(selectedAnswers).length} Unattempted
      </div>

      {error && <div>Error: {error.message}</div>}
      {!isLoaded && !error && <div>Loading...</div>}
      {isLoaded && items.length === 0 && <div>No items to display</div>}

      {isLoaded && items.length > 0 && (
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginRight: "10px" }}>
          {isAGEorACiE && (
            <>
              <h3 style={{ fontWeight: "bold", textAlign: "center" }}>Group A (1 Mark Questions)</h3>
              {groupedQuestions.groupA.map(renderQuestion)}
              <h3 style={{ fontWeight: "bold", textAlign: "center" }}>Group B (2 Marks Questions)</h3>
              {groupedQuestions.groupB.map(renderQuestion)}
            </>
          )}
          {!isAGEorACiE && groupedQuestions.all.map(renderQuestion)}
        </ul>
      )}

      <button className="submit-button" onClick={handleSubmit} disabled={!Object.keys(selectedAnswers).length}>
        Submit
      </button>

      <ReportQuestion
          questionId={reportQuestionId}
          isOpen={reportModalOpen}
          onClose={() => setReportModalOpen(false)}
        />
    </div>
  )

  function renderQuestion(item) {
    return (
      <li key={item.id} className={selectedAnswers[item.id] ? "" : "unattempted"}>
        <br />
        <div className="flex justify-between items-start">

        <h6>
          <div ref={questionRef} style={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}>
            <span style={{ marginRight: "10px", whiteSpace: "nowrap" }}>Q {questionNumber++}:</span>
            <span style={{ flex: 1 }}>{parse(item.question)}</span>
          </div>

        </h6>
              <button
                            onClick={() => handleReportQuestion(item.id)}
                            className="ml-2 px-2 py-1 text-sm bg-gray-200 hover:bg-gray-300 rounded"
                          >
                            <AlertTriangle className="w-4 h-4 mr-1 inline" />
                            Report
                          </button>
           </div>
                       


        <ul style={{ listStyleType: "none", padding: "0", margin: "0", textAlign: "left" }}>
          {["1", "2", "3", "4"].map((optionNumber) => (
            <li
              key={optionNumber}
              className={getOptionClassName(item.id, optionNumber)}
              onClick={() => handleOptionClick(item.id, optionNumber)}
              style={{ ...optionStyle, marginBottom: "5px", padding: "10px", cursor: "pointer" }}
            >
              <span className="option-text">{parse(item[`option${optionNumber}`])}</span>
            </li>
          ))}
        </ul>
        {showResultDetails && (
          <div className="result-details">
            <p style={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}>
              Correct Option: {item.correctOpt}
            </p>
            <div className="result-details" style={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}>
              <strong>Explanation: </strong> {parse(item.explanation)}
            </div>
          </div>
        )}
      </li>
    )
  }
}

export default WeeklyExam


// import React, { useState, useEffect, useRef, useContext } from "react"
// import axios from "axios"
// import "../../components/quiz.css"
// import Swal from "sweetalert2"
// import parse from "html-react-parser"
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
// import AuthContext from "../../context/AuthContext"

// const WeeklyExam = () => {
//   const [error, setError] = useState(null)
//   const [isLoaded, setIsLoaded] = useState(false)
//   const [items, setItems] = useState([])
//   const [selectedAnswers, setSelectedAnswers] = useState({})
//   const questionRef = useRef(null)
//   const [showResultButton, setShowResultButton] = useState(false)
//   const [optionStyle, setOptionStyle] = useState({})
//   const [disabledQuestions] = useState([])
//   const location = useLocation()
//   const id = location.state?.id
//   const testname = location.state?.testname
//   const { authTokens, user } = useContext(AuthContext)
//   const [submissionResponse, setSubmissionResponse] = useState(null)
//   const [showResultDetails, setShowResultDetails] = useState(false)
//   // const [timeLeft, setTimeLeft] = useState(7200) // 2 hours in seconds
//   let questionNumber = 1
//   const courseCode = location.state?.courseCode

//   const isAGEorACiE = courseCode === "AGE" || courseCode === "ACiE" || false
//   const isAMINPSC = courseCode === "AMIN-PSC"

//   useEffect(() => {
//     const viewport = document.querySelector("meta[name=viewport]")
//     if (!viewport) {
//       const meta = document.createElement("meta")
//       meta.name = "viewport"
//       meta.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
//       document.head.appendChild(meta)
//     }
//   }, [])

//   // useEffect(() => {
//   //   const timer = setInterval(() => {
//   //     setTimeLeft((prevTime) => {
//   //       if (prevTime <= 0) {
//   //         clearInterval(timer)
//   //         handleSubmit()
//   //         return 0
//   //       }
//   //       return prevTime - 1
//   //     })
//   //   }, 1000)

//   //   return () => clearInterval(timer)
//   // }, [])

//   // const formatTime = (seconds) => {
//   //   const hours = Math.floor(seconds / 3600)
//   //   const minutes = Math.floor((seconds % 3600) / 60)
//   //   const remainingSeconds = seconds % 60
//   //   return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`
//   // }

//   const countUnattempted = () => items.length - Object.keys(selectedAnswers).length

//   useEffect(() => {
//     axios
//       .get(`https://nec.geoneer.com.np/api/exam/weekly-tests/${id}/questions/`)
//       .then((response) => {
//         setIsLoaded(true)
//         setItems(response.data)
//       })
//       .catch((error) => {
//         setIsLoaded(true)
//         setError(error)
//       })
//   }, [id])

//   useEffect(() => {
//     if (questionRef.current) {
//       const questionWidth = questionRef.current.getBoundingClientRect().width
//       setOptionStyle({ width: `${questionWidth}px` })
//     }
//   }, [])

//   const handleOptionClick = (itemId, option) => {
//     if (!selectedAnswers[itemId] && !showResultButton) {
//       setSelectedAnswers((prevState) => ({
//         ...prevState,
//         [itemId]: option.toString(),
//       }))
//     }
//   }

//   const calculateScore = () => {
//     let score = 0
//     let correctCount = 0
//     let wrongCount = 0

//     items.forEach((item) => {
//       const selectedAnswer = selectedAnswers[item.id]
//       if (selectedAnswer === item.correctOpt.toString()) {
//         if (isAGEorACiE) {
//           score += item.weightage === 1 ? 1 : 2
//         } else if (isAMINPSC) {
//           score += 2
//         } else {
//           score += 1
//         }
//         correctCount++
//       } else if (selectedAnswer !== undefined) {
//         if (!isAGEorACiE && !isAMINPSC) {
//           score -= 0.2 // Deduct 20% for each wrong answer only for non-AGE/ACiE and non-AMIN-PSC
//         }
//         wrongCount++
//       }
//     })

//     score = Math.max(score, 0)
//     return { score, correctCount, wrongCount }
//   }

//   const handleSubmit = async () => {
//     setShowResultButton(true)

//     const { score, correctCount, wrongCount } = calculateScore()
//     const totalScore = isAMINPSC ? items.length * 2 : items.length

//     setShowResultDetails(true)

//     Swal.fire({
//       title: "Result",
//       html: `
//         <p>You answered ${correctCount} questions correctly!</p>
//         <p>You answered ${wrongCount} questions incorrectly.</p>
//         <p>Your score is ${score.toFixed(2)} out of ${totalScore}</p>
//       `,
//       icon: "info",
//       timer: 3600000,
//     })

//     const payload = {
//       weekly_test: id,
//       score: score,
//       total_score: String(totalScore).padStart(3, "0"),
//       correct_count: correctCount,
//       wrong_count: wrongCount,
//       unattempted_count: countUnattempted(),
//       total_questions: items.length,
//       questions: items.map((item) => ({
//         question: item.id,
//         user_selected_option: selectedAnswers[item.id] || null,
//         is_correct: item.correctOpt.toString() === selectedAnswers[item.id],
//       })),
//     }

//     try {
//       const response = await axios.post(
//         `https://nec.geoneer.com.np/api/exam/user/tests/store/${user.user_id}/${id}/`,
//         payload,
//         {
//           headers: {
//             Authorization: `Bearer ${authTokens?.access}`,
//           },
//         },
//       )
//       setSubmissionResponse(response.data)
//       Swal.fire({
//         title: "Your test has been submitted.",
//         icon: "success",
//         toast: true,
//         timer: 6000,
//         position: "top-right",
//         timerProgressBar: true,
//         showConfirmButton: false,
//       })
//       setShowResultButton(false)
//     } catch (error) {
//       console.error(error)
//       console.log(submissionResponse)
//       Swal.fire({
//         title: "Error!",
//         text: "Your test has already been submitted.",
//         icon: "error",
//         toast: true,
//         position: "top-end",
//         showConfirmButton: false,
//         timer: 3000,
//       })
//     }
//   }

//   const getOptionClassName = (itemId, option) => {
//     const item = items.find((item) => item.id === itemId)
//     const selectedOption = selectedAnswers[itemId]

//     if (showResultButton) {
//       if (selectedOption === option.toString()) {
//         return selectedOption === item.correctOpt.toString() ? "option selected" : "option wrong"
//       }
//       return option.toString() === item.correctOpt.toString() ? "option selected" : "option"
//     }

//     if (selectedOption === option.toString()) {
//       return "option selected"
//     }
//     if (disabledQuestions.includes(itemId)) {
//       return "option disabled"
//     }
//     return "option"
//   }

//   const groupQuestions = (questions) => {
//     if (!isAGEorACiE) return { all: questions }

//     return {
//       groupA: questions.filter((q) => q.weightage === 1),
//       groupB: questions.filter((q) => q.weightage === 4),
//     }
//   }

//   const groupedQuestions = groupQuestions(items)

//   return (
//     <div className="quiz-container">
//       <h6>
//         <strong>{testname}</strong>
//       </h6>
//       <br />
//       <div className="attendance-count">
//         {Object.keys(selectedAnswers).length} Questions Attended <br />
//         {items.length - Object.keys(selectedAnswers).length} Unattempted
//       </div>
//       {/* <div className="timer"> {formatTime(timeLeft)}</div> */}

//       {error && <div>Error: {error.message}</div>}
//       {!isLoaded && !error && <div>Loading...</div>}
//       {isLoaded && items.length === 0 && <div>No items to display</div>}

//       {isLoaded && items.length > 0 && (
//         <ul style={{ listStyleType: "none", paddingLeft: "0", marginRight: "10px" }}>
//           {isAGEorACiE && (
//             <>
//               <h3 style={{ fontWeight: "bold", textAlign: "center" }}>Group A (1 Mark Questions)</h3>
//               {groupedQuestions.groupA.map(renderQuestion)}
//               <h3 style={{ fontWeight: "bold", textAlign: "center" }}>Group B (2 Marks Questions)</h3>
//               {groupedQuestions.groupB.map(renderQuestion)}
//             </>
//           )}
//           {!isAGEorACiE && groupedQuestions.all.map(renderQuestion)}
//         </ul>
//       )}

//       <button className="submit-button" onClick={handleSubmit} disabled={!Object.keys(selectedAnswers).length}>
//         Submit
//       </button>
//     </div>
//   )

//   function renderQuestion(item) {
//     return (
//       <li key={item.id} className={selectedAnswers[item.id] ? "" : "unattempted"}>
//         <br />
//         <h6>
//           <div ref={questionRef} style={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}>
//             <span style={{ marginRight: "10px", whiteSpace: "nowrap" }}>Q {questionNumber++}:</span>
//             <span style={{ flex: 1 }}>{parse(item.question)}</span>
//           </div>
//         </h6>

//         <ul style={{ listStyleType: "none", padding: "0", margin: "0", textAlign: "left" }}>
//           {["1", "2", "3", "4"].map((optionNumber) => (
//             <li
//               key={optionNumber}
//               className={getOptionClassName(item.id, optionNumber)}
//               onClick={() => handleOptionClick(item.id, optionNumber)}
//               style={{ ...optionStyle, marginBottom: "5px", padding: "10px", cursor: "pointer" }}
//             >
//               <span className="option-text">{parse(item[`option${optionNumber}`])}</span>
//             </li>
//           ))}
//         </ul>
//         {showResultDetails && (
//           <div className="result-details">
//             <p style={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}>
//               Correct Option: {item.correctOpt}
//             </p>
//             <div className="result-details" style={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}>
//               <strong>Explanation: </strong> {parse(item.explanation)}
//             </div>
//           </div>
//         )}
//       </li>
//     )
//   }
// }

// export default WeeklyExam

