// import React ,{useState,useEffect} from 'react';
// import './Profile.css'; // Import a CSS file for styling
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import { Tab, Tabs } from 'react-bootstrap';
// import Button from 'react-bootstrap/Button';


// const Profile = ({ match }) => {

//   const [key, setKey] = useState('tab1');


//   const [userDetails, setUserDetails] = useState(null);
  
//   useEffect(() => {
//     // Retrieve user details from localStorage once (only on mount)
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       setUserDetails(JSON.parse(storedUserDetails));
//     }
//   }, []); // Empty dependency array means this effect only runs once on mount

//   useEffect(() => {
//     // If userDetails is available and courseName is part of the URL, fetch the course details
//     if (userDetails && match.params.courseName) {

//     }
//   }, [userDetails, match.params.courseName]); 
  
//   return (
    
//     <div className="profile-container">
       

//        {userDetails ? (
//   <>
//     <div className="profile-header">
//       <h1>User Profile</h1>
//       {/* <p>Status: {is_active ? 'Active' : 'Inactive'}</p> */}
//     </div>
//     <div className="row">
//       <div className="col-lg-6 col-md-6 col-sm-12">
//         <div className="profile-details">
//           <div className="profile-info">
//             <h2>Personal Information</h2>
//             <p><strong>Email:{userDetails.email}</strong> {}</p>
//             <p><strong>First Name:</strong> {userDetails.first_name}</p>
//             <p><strong>Last Name:</strong> {userDetails.last_name}</p>
//             <p><strong>Phone Number :{userDetails.phone_number}</strong></p>
//             <a href='/update-password/'>
//               <Button variant="warning btn-sm">Update Password</Button>
//             </a>
//           </div>
//         </div>
//       </div>
//       <div className="col-lg-6 col-md-6 col-sm-12">
//         <div className="course-info">
//           <h2>Course Information</h2>
//           <p><strong>Course Name:</strong> {userDetails.course.courseName}</p>
//           <p><strong>Course Code:</strong> {userDetails.course.courseCode}</p>
//           <p><strong>Description:</strong> {userDetails.course.courseDescription}</p>
//           <p><strong>Validity :</strong> {userDetails.validity}</p>
//         </div>
//       </div>
//     </div>
//   </>
// ) : (
//   <p>Loading course details...</p>
// )}


// <div>
  
// </div>


//   <hr/>

//       <div className="container mt-3">
//         <h4>Results</h4>
//       <Tabs
//                 id="controlled-tab-example"
//                 activeKey={key}
//                 onSelect={(k) => setKey(k)}
//                 className="mb-3"
//             >
//                 <Tab eventKey="tab1" title="Chapterwise Test Result">
//                     <div className="table-responsive">
//                         <table className="table">
//                             <thead>
//                                 <tr>
//                                     <th>S.N.</th>
//                                     <th>Date /Time</th>
//                                     <th>Chapter</th>
//                                     <th>Sub-Chapter</th>
//                                     <th>Score</th>
//                                     <th>Total Score</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 <tr>
//                                     <td>Data 1</td>
//                                     <td>Data 2</td>
//                                     <td>Data 3</td>
//                                     <td>Data 4</td>
//                                     <td>Data 5</td>
//                                     <td>Data 6</td>
//                                 </tr>
//                             </tbody>
//                         </table>
//                     </div>
//                 </Tab>
//                 <Tab eventKey="tab2" title="Mock Exam Results">
//                     <div className="table-responsive">
//                         <table className="table">
//                             <thead>
//                                 <tr>
//                                 <th>S.N.</th>
//                                 <th>Date /Time</th>
//                                 <th>Score</th>
//                                 <th>Total Score</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 <tr>
//                                     <td>Data 3</td>
//                                     <td>Data 4</td>
//                                     <td>Data 5</td>
//                                     <td>Data 6</td>
//                                 </tr>
//                             </tbody>
//                         </table>
//                     </div>
//                 </Tab>
//                 <Tab eventKey="tab3" title="Weekly Test Results">
//                     <div className="table-responsive">
//                         <table className="table">
//                             <thead>
//                                 <tr>
//                                 <th>S.N.</th>
//                                 <th>Date /Time</th>
//                                 <th>Test Name</th>
//                                 <th>Score</th>
//                                 <th>Total Score</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 <tr>
//                                     <td>Data 5</td>
//                                     <td>Data 6</td>
//                                     <td>Data 7</td>
//                                     <td>Data 8</td>
                                      
//                                 </tr>
//                             </tbody>
//                         </table>
//                     </div>
//                 </Tab>
//             </Tabs>
//       </div>
     
    
   
//       </div>
      
    
//   );
// };

// export default Profile;


// import React, { useState, useEffect } from 'react';
// import './Profile.css'; // Import a CSS file for styling
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// // import { Tab, Tabs } from 'react-bootstrap';
// import Button from 'react-bootstrap/Button';

// const Profile = ({ match }) => {
//   // const [key, setKey] = useState('tab1');
//   const [userDetails, setUserDetails] = useState(null);
//   const [isExpired, setIsExpired] = useState(false);

//   useEffect(() => {
//     // Retrieve user details from localStorage once (only on mount)
//     const storedUserDetails = localStorage.getItem('userDetails');
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);
//       setUserDetails(parsedDetails);

//       // Check if the validity date is expired
//       const today = new Date();
//       const validityDate = new Date(parsedDetails.validity);
//       if (validityDate < today) {
//         setIsExpired(true);
//       }
//     }
//   }, []);

//   return (
//     <div className="profile-container">
//       {userDetails ? (
//         <>
//           <div className="profile-header">
//             <h1>User Profile</h1>
//           </div>

//           {isExpired && (
//         <div className="marquee-container">
//         <p>Please pay the fee for activation of the course</p>
//       </div>
//           )}

//           <div className="row">
//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <div className="profile-details">
//                 <div className="profile-info">
//                   <h2>Personal Information</h2>
//                   <p>
//                     <strong>Email:</strong> {userDetails.email}
//                   </p>
//                   <p>
//                     <strong>First Name:</strong> {userDetails.first_name}
//                   </p>
//                   <p>
//                     <strong>Last Name:</strong> {userDetails.last_name}
//                   </p>
//                   <p>
//                     <strong>Phone Number:</strong> {userDetails.phone_number}
//                   </p>
//                   <a href="/update-password/">
//                     <Button variant="warning btn-sm">Update Password</Button>
//                   </a>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <div className="course-info">
//                 <h2>Course Information</h2>
//                 <p>
//                   <strong>Course Name:</strong> {userDetails.course.courseName}
//                 </p>
//                 <p>
//                   <strong>Course Code:</strong> {userDetails.course.courseCode}
//                 </p>
//                 <p>
//                   <strong>Description:</strong> {userDetails.course.courseDescription}
//                 </p>
//                 <p>
//                   <strong>Validity:</strong>{' '}
//                   <span style={{ color: isExpired ? 'red' : 'inherit' }}>
//                     {userDetails.validity}
//                   </span>
//                 </p>
//               </div>
//             </div>
//           </div>
//         </>
//       ) : (
//         <p>Loading course details...</p>
//       )}

//       <hr />

//       {/* <div className="container mt-3">
//         <h4>Results</h4>
//         <Tabs
//           id="controlled-tab-example"
//           activeKey={key}
//           onSelect={(k) => setKey(k)}
//           className="mb-3"
//         >
//           <Tab eventKey="tab1" title="Chapterwise Test Result">
//             <div className="table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th>S.N.</th>
//                     <th>Date / Time</th>
//                     <th>Chapter</th>
//                     <th>Sub-Chapter</th>
//                     <th>Score</th>
//                     <th>Total Score</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>Data 1</td>
//                     <td>Data 2</td>
//                     <td>Data 3</td>
//                     <td>Data 4</td>
//                     <td>Data 5</td>
//                     <td>Data 6</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//           </Tab>
//           <Tab eventKey="tab2" title="Mock Exam Results">
//             <div className="table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th>S.N.</th>
//                     <th>Date / Time</th>
//                     <th>Score</th>
//                     <th>Total Score</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>Data 3</td>
//                     <td>Data 4</td>
//                     <td>Data 5</td>
//                     <td>Data 6</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//           </Tab>
//           <Tab eventKey="tab3" title="Weekly Test Results">
//             <div className="table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th>S.N.</th>
//                     <th>Date / Time</th>
//                     <th>Test Name</th>
//                     <th>Score</th>
//                     <th>Total Score</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>Data 5</td>
//                     <td>Data 6</td>
//                     <td>Data 7</td>
//                     <td>Data 8</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//           </Tab>
//         </Tabs>
//       </div> */}
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import './Profile.css';
// import { Button } from 'react-bootstrap';
// import qrCodeImage from './images/geoqr.jpeg';
// import { X } from 'lucide-react';

// const Profile = () => {
//   const [userDetails, setUserDetails] = useState(null);
//   const [isExpired, setIsExpired] = useState(false);
//   const [courseCode,setCourseCode]=useState(null)

//   useEffect(() => {
//     const storedUserDetails = localStorage.getItem('userDetails');
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);
//       setUserDetails(parsedDetails);
//       setCourseCode(parsedDetails?.course?.courseCode)

//       const today = new Date();
//       const validityDate = new Date(parsedDetails.validity);
//       if (validityDate < today) {
//         setIsExpired(true);
//       }
//     }
//   }, []);

//   if (!userDetails) {
//     return <div className="loading">Loading course details...</div>;
//   }

//   return (
//     <div className="profile-container">
//       <div className="profile-header">
//         <h1>User Profile</h1>
//       </div>

//       {isExpired && (
//          <div>
//         <div className="marquee-container">
//           <p>Please pay the fee for activation of the course</p>
//         </div>
//        {/* {
//         if courseCode==PSC-AMIN:
//         <p>Please pay the fee for activation of the course. Scan the QR Code for the payment."</p>       

//        } */}
//         <p>Please pay the fee for activation of the course. Scan the QR Code for the payment."</p>       
//                 {/* QR Code */}
//                 <div className="flex justify-center">
//             <img
//               src={qrCodeImage}
//               alt="QR Code"
//               className="w-40 h-40 object-contain border border-green-200 rounded-lg shadow-sm"
//             />

          
//           {/* Additional Instructions */}
//           <div className="text-sm text-gray-600">
//             <p>
//               After payment, send the payment screenshot to whatsapp number  9857885572. If your account is  activated within 5 minutes of
//               payment, please contact: 📞 9857885572 📞 9862467445
//             </p>
//           </div>
//           </div>


//           </div>
//       )}

//       <div className="profile-content">
//         <div className="profile-section">
//           <h2>Personal Information</h2>
//           <div className="info-grid">
//             <div className="info-item">
//               <strong>Email:</strong>
//               <span>{userDetails.email}</span>
//             </div>
//             <div className="info-item">
//               <strong>First Name:</strong>
//               <span>{userDetails.first_name}</span>
//             </div>
//             <div className="info-item">
//               <strong>Last Name:</strong>
//               <span>{userDetails.last_name}</span>
//             </div>
//             <div className="info-item">
//               <strong>Phone Number:</strong>
//               <span>{userDetails.phone_number}</span>
//             </div>
//           </div>
//           <div className="button-container">
//             <Button variant="warning" href="/update-password/">
//               Update Password
//             </Button>
//           </div>
//         </div>

//         <div className="profile-section">
//           <h2>Course Information</h2>
//           <div className="info-grid">
//             <div className="info-item">
//               <strong>Course Name:</strong>
//               <span>{userDetails.course.courseName}</span>
//             </div>
//             <div className="info-item">
//               <strong>Course Code:</strong>
//               <span>{userDetails.course.courseCode}</span>
//             </div>
//             <div className="info-item">
//               <strong>Description:</strong>
//               <span>{userDetails.course.courseDescription}</span>
//             </div>
//             <div className="info-item">
//               <strong>Validity:</strong>
//               <span className={isExpired ? 'expired' : ''}>
//                 {userDetails.validity}
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Profile;

import React, { useState, useEffect } from 'react';
import './Profile.css';
import { Button } from 'react-bootstrap';
import qrCodeImage from './images/geoqr.jpeg';


const Profile = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [courseCode, setCourseCode] = useState(null);

  useEffect(() => {
    const storedUserDetails = localStorage.getItem('userDetails');
    if (storedUserDetails) {
      const parsedDetails = JSON.parse(storedUserDetails);
      setUserDetails(parsedDetails);
      setCourseCode(parsedDetails?.course?.courseCode);

      const today = new Date();
      const validityDate = new Date(parsedDetails.validity);
      if (validityDate < today) {
        setIsExpired(true);
      }
    }
  }, []);

  const getPaymentInstructions = () => {
    switch (courseCode) {
      case 'AMIN-PSC':
        return 'Please pay Rs. 1500 for activation of the course.';
      case 'AGE':
        return 'Please pay Rs. 7000 for activation of the course.';
      case 'AGiCe':
        return 'Please pay Rs. 10000 for activation of the course.';
      default:
        return 'Please pay the fee for activation of the course.';
    }
  };

  if (!userDetails) {
    return <div className="loading">Loading course details...</div>;
  }

  return (
    <div className="profile-container max-w-6xl mx-auto px-4 py-8">
      <div className="profile-header text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-800">User Profile</h1>
      </div>

      {isExpired && (
        <div className="payment-section mb-10">
          <div className="bg-red-200 rounded-lg p-4 mb-6">
            <p className="text-red-700 text-center text-lg font-semibold">
              {getPaymentInstructions()}
            </p>
          </div>
          
          <div className="payment-instructions bg-white rounded-lg shadow-lg p-6 max-w-2xl mx-auto">
            <p className="text-center text-lg mb-6 text-gray-700">
              {getPaymentInstructions()} Scan the QR Code for the payment.
            </p>
            
            <div className="flex flex-col md:flex-row items-center justify-center gap-8">
              <div className="qr-container relative">
                <img
                  src={qrCodeImage || "/placeholder.svg"}
                  alt="QR Code"
                  className="w-80 h-80 object-contain rounded-lg shadow-md border-4 border-teal-100"
                />
                <div className="absolute bottom-0 left-0 right-0 bg-teal-50 rounded-b-lg p-2">
                  <p className="text-center text-sm text-teal-700">Geoneer Geospatial Service</p>
                </div>
              </div>

              <div className="payment-info max-w-md">
                <div className="bg-blue-50 rounded-lg p-4">
                  <p className="text-gray-700 text-sm leading-relaxed">
                    After payment, send the payment screenshot to WhatsApp number{' '}
                    <span className="font-semibold">9857885572</span>.
                  </p>
                  <p className="text-gray-700 text-sm mt-2">
                    If your account is not activated within 5 minutes of payment, please contact:
                  </p>
                  <div className="contact-numbers mt-2 flex flex-col gap-1">
                    <p className="text-blue-600 font-medium">📞 9857885572</p>
                    <p className="text-blue-600 font-medium">📞 9862467445</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="profile-content grid md:grid-cols-2 gap-8">
        <div className="profile-section bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-semibold mb-6 text-gray-800 border-b pb-2">
            Personal Information
          </h2>
          <div className="info-grid space-y-4">
            <div className="info-item">
              <strong className="text-gray-600">Email:</strong>
              <span className="ml-2 text-gray-800">{userDetails.email}</span>
            </div>
            <div className="info-item">
              <strong className="text-gray-600">First Name:</strong>
              <span className="ml-2 text-gray-800">{userDetails.first_name}</span>
            </div>
            <div className="info-item">
              <strong className="text-gray-600">Last Name:</strong>
              <span className="ml-2 text-gray-800">{userDetails.last_name}</span>
            </div>
            <div className="info-item">
              <strong className="text-gray-600">Phone Number:</strong>
              <span className="ml-2 text-gray-800">{userDetails.phone_number}</span>
            </div>
          </div>
          <div className="button-container mt-6">
            <Button 
              variant="warning" 
              href="/update-password/"
              className="w-full md:w-auto"
            >
              Update Password
            </Button>
          </div>
        </div>

        <div className="profile-section bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-semibold mb-6 text-gray-800 border-b pb-2">
            Course Information
          </h2>
          <div className="info-grid space-y-4">
            <div className="info-item">
              <strong className="text-gray-600">Course Name:</strong>
              <span className="ml-2 text-gray-800">{userDetails.course.courseName}</span>
            </div>
            <div className="info-item">
              <strong className="text-gray-600">Course Code:</strong>
              <span className="ml-2 text-gray-800">{userDetails.course.courseCode}</span>
            </div>
            <div className="info-item">
              <strong className="text-gray-600">Description:</strong>
              <span className="ml-2 text-gray-800">{userDetails.course.courseDescription}</span>
            </div>
            <div className="info-item">
              <strong className="text-gray-600">Validity:</strong>
              <span className={`ml-2 ${isExpired ? 'text-red-600 font-semibold' : 'text-gray-800'}`}>
                {userDetails.validity}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;