// import React from 'react';

// const CourseDetail = () => {
//   return (
//     <div className="container mt-4">
//       <div className="row">
//         <h3>Available Exams</h3>
//         {/* Card 1 */}
//         <div className="col-md-3">
//           <div className="card">
//             <img
//               src="https://picsum.photos/id/20/80/80"
//               className="card-img-top"
//               alt="Card 1"
//               style={{  height: "180px", borderRadius: "3%" }}
//             />
//             <div className="card-body">
//               <h5 className="card-title">Mock Test</h5>
//               <p className="card-text">
//                 This is a brief description of the content for card 1.
//               </p>
//               <div class="btn-group" role="group" aria-label="Basic example">
//               <a href='/weekly-tests/' type="button" class="btn btn-success btn-sm">Take Test</a>
//               <a href="/mock-test-results/" type="button" class="btn btn-primary btn-sm">View Results</a>
                
//             </div>
//             </div>
//           </div>
//         </div>

//         {/* Card 2 */}
//         <div className="col-md-3">
//           <div className="card">
//             <img
//               src="https://picsum.photos/id/11/80/80"
//               className="card-img-top"
//               alt="Card 2"
//               style={{  height: "180px", borderRadius: "3%" }}
//             />
//             <div className="card-body">
//               <h5 className="card-title">Weekly Test (I Paper)</h5>
//               <p className="card-text">
//                 This is a brief description of the content for card 2.
//               </p>
//               <div class="btn-group" role="group" aria-label="Basic example">
//                 <a href='/weekly-tests/' type="button" class="btn btn-success btn-sm">View Tests</a>
//                 <a href='/weekly-test-results/' type="button" class="btn btn-primary btn-sm">View Results</a>
                
//             </div>
//             </div>
//           </div>
//         </div>

//         <div className="col-md-3">
//           <div className="card">
//             <img
//               src="https://picsum.photos/id/19/80/80"
//               className="card-img-top"
//               alt="Card 2"
//               style={{  height: "180px", borderRadius: "3%" }}
//             />
//             <div className="card-body">
//               <h5 className="card-title">Weekly Test (II Paper)</h5>
//               <p className="card-text">
//                 This is a brief description of the content for card 2.
//               </p>
//               <div class="btn-group" role="group" aria-label="Basic example">
//                 <a href='/weekly-tests/' type="button" class="btn btn-success btn-sm">View Tests</a>
//                 <a href='/weekly-test-results/' type="button" class="btn btn-primary btn-sm">View Results</a>
                
//             </div>
//             </div>
//           </div>
//         </div>
//         </div>
// <hr />
//         <div className="row">
//         <h3>Chapter and Subchapter wise Practice</h3>

//         {/* Card 1 */}
//         <div className="col-md-3">
//           <div className="card">
//             <img
//               src="https://picsum.photos/id/27/80/80"
//               className="card-img-top"
//               alt="Card 1"
//               style={{  height: "180px", borderRadius: "3%" }}
//             />
//             <div className="card-body">
//               <h5 className="card-title">General Awareness and Contemporary Issues</h5>
//               <p className="card-text">
//                 Use Loop Here to Iterate all the chapters
//               </p>
//             <div class="btn-group" role="group" aria-label="Basic example">
//                 <a href='#' type="button" class="btn btn-success btn-sm">Pratice</a>
//                 <a href='/sub-chapters/' button type="button" class="btn btn-success btn-sm">Explore</a>
//                 <a href='/chapter-test-results/' type="button" class="btn btn-success btn-sm">View Results</a>
//             </div>
//             </div>
//           </div>
//         </div>

//         {/* Card 2 */}
//         <div className="col-md-3">
//           <div className="card">
//             <img
//               src="https://picsum.photos/id/16/80/80"
//               className="card-img-top"
//               alt="Card 2"
//               style={{  height: "180px", borderRadius: "3%" }}
//             />
//             <div className="card-body">
//               <h5 className="card-title">General Reasoning Test</h5>
//               <p className="card-text">
//                 This is a brief description of the content for card 2.
//               </p>
//               <div class="btn-group" role="group" aria-label="Basic example">
//               <a href='#' type="button" class="btn btn-success btn-sm">View Tests</a>
//               <a href='#' type="button" class="btn btn-primary btn-sm">View Results</a>
                
//             </div>
//             </div>
//           </div>
//         </div>

//       </div>
//       </div>   
    
//   );
// };

// export default CourseDetail;


// Above is the referance code for UI


// import React, { useState, useEffect } from 'react';
// import SubChapter from "./Sub-chapter";
// import { Button } from 'react-bootstrap';
// import { useHistory } from 'react-router-dom';
// import ChaperWiseExam from './exams/ChaperWiseExam';
// // import MockTestExam from './exams/MockTestExam';
// import mock from './images/MOCK TEST .png'
// import week from './images/weekly test.jpg'
// import ChapterTestResults from './results/ChapterTestResults';

// import ChapterWiseSubjectiveExam from './exams/ChapterWiseSubjectiveExam';


// const CourseDetail = () => {
//   const [chapters, setChapters] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [chapterCode, setchapterCode] = useState(null);
//   const [courseCode, setcourseCode] = useState('SO-PSC');
//   const [chapterId, setchapterId] = useState(null);
//   const [schapters, setsChapters] = useState([]);




//   const history = useHistory();

//   useEffect(() => {
//     // Fetch chapters from the API
//     const fetchChapters = async () => {
//       try {
//         const response = await fetch('https://nec.geoneer.com.np/api/exam/chapters/SO-PSC/');
//         if (!response.ok) {
//           throw new Error('Failed to fetch chapters');
//         }
//         const data = await response.json();
//         setChapters(data); // Update chapters state
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchChapters();
//   }, []); 


//   useEffect(() => {
//     const fetchsChapters = async () => {
//       try {
//         const response = await fetch('https://nec.geoneer.com.np/api/exam/schapters/SO-PSC/');
//         if (!response.ok) {
//           throw new Error('Failed to fetch chapters');
//         }
//         const data = await response.json();
//         setsChapters(data); // Update chapters state
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchsChapters();
//   }, []); // Dependency array ensures the effect runs only once

//   const handleTakeExam = (chapterCode , chapterId) => {
//     setchapterCode(chapterCode);
//     console.log(chapterCode)
//     setchapterId(chapterId);
//     // Use history.push to navigate to the '/chapter' route with props
//     history.push({
//       pathname: '/subchapters',
//       state: {
//         chapterCode: chapterCode,
//         chapterId: chapterId,
      
//         // chapterName: chapterName,
//         // Add other props as needed
//       },






//     });




  
  
//   };


//   const handleTakeExamChapterWise = (chapterCode,chapterId) => {
//     setchapterCode(chapterCode);
//     setchapterId(chapterId);

//     // Use history.push to navigate to the '/chapterwise-exam' route with props
//     history.push({
//       pathname: '/chapterwise-exam',
   
//       state: {
//         chapterCode: chapterCode,
//         chapterId:chapterId,

//         // Add other props as needed
//       },
//     });
//   };


//   const handleTakeExamSubChapterWise = (chapterCode) => {
//     setchapterCode(chapterCode);

//     // Use history.push to navigate to the '/chapterwise-exam' route with props
//     history.push({
//       pathname: '/chapterwise-subjective-exam',
   
//       state: {
//         chapterCode: chapterCode,
//         // chapterId:chapterId,

//         // Add other props as needed
//       },
//     });
//   };
//   const handleTakeExamChapterWiseResult = (chapterCode) => {
//     setchapterCode(chapterCode);
//     // Use history.push to navigate to the '/chapterwise-exam' route with props
//     history.push({
  
//       pathname:'/chapter-test-results',
//       state: {
//         chapterCode: chapterCode,
//         // Add other props as needed
//       },
//     });
//   };



//   const handleMockExam = (courseCode) => {
//     setcourseCode(courseCode);
//     console.log(courseCode)
//     // Use history.push to navigate to the '/chapter' route with props
//     history.push({
//       pathname: '/mock-test',
//       state: {
//         courseCode: courseCode,
      
//         // chapterName: chapterName,
//         // Add other props as needed
//       },






//     });




  
  
//   };


  
//   return (
//     <div className="container mt-4">
//       <div className="row">
//         <h3>Available Exams</h3>
//         <div className=" col-lg-3 col-md-4 col-sm-6">
//          <div className="card">         
//             <img
//               src= {mock}
//               className="card-img-top"
//               alt="Card 1"
//               style={{  height: "180px", borderRadius: "3%" }}
//             />
//             <div className="card-body">
//               <h5 className="card-title">Mock Test</h5>
//               {/* <p className="card-text">
//                 This is a brief description of the content for card 1.
//               </p> */}
//               <div class="btn-group" role="group" aria-label="Basic example">
//               <Button variant="success btn-sm" onClick={() => handleMockExam(courseCode)}>
//                   {console.log(courseCode)}
//                 Take Exam
//               </Button>
//                             <a href="/mock-test-results/" type="button" class="btn btn-primary btn-sm">View Results</a>
                
//             </div>

//             </div>
//             </div>
//           </div>


//           <div className="col-lg-3 col-md-4 col-sm-6">
//         <div className="card">
//             <img
//               src= {week}
//               className="card-img-top"
//               alt="Card 2"
//               style={{  height: "180px", borderRadius: "3%" }}
//             />
//             <div className="card-body">
//               <h5 className="card-title">Weekly Test (I Paper)</h5>
//               {/* <p className="card-text">
//                 This is a brief description of the content for card 2.
//               </p> */}
//               <div class="btn-group" role="group" aria-label="Basic example">
//                 <a href='/weekly-tests/' type="button" class="btn btn-success btn-sm">View Tests</a>
//                 <a href='/weekly-test-results/' type="button" class="btn btn-primary btn-sm">View Results</a>
                
//             </div>
//             </div>
//           </div>
//         </div>



//         <div className="col-lg-3 col-md-4 col-sm-6">
//         <div className="card">
//             <img
//               src= {week}
//               className="card-img-top"
//               alt="Card 2"
//               style={{  height: "180px", borderRadius: "3%" }}
//             />
//             <div className="card-body">
//               <h5 className="card-title">Weekly Test (II Paper)</h5>
//               {/* <p className="card-text">
//                 This is a brief description of the content for card 2.
//               </p> */}
//               <div class="btn-group" role="group" aria-label="Basic example">
//                 <a href='/sub-weekly-test/' type="button" class="btn btn-success btn-sm">View Tests</a>
//                 <a href='/weekly-test-results/' type="button" class="btn btn-primary btn-sm">View Results</a>
                
//             </div>
//             </div>
//           </div>
//         </div>
            
//       </div>

            


//       <hr />
//       <div className="row">
//         <h3> First Paper Chapter and Subchapter wise Practice</h3>

//         {/* Display loading state */}
//         {loading && <p>Loading chapters...</p>}
//         {error && <p className="text-danger">Error: {error}</p>}

//         {/* Dynamically render chapters */}
//         {chapters.map((chapter) => (
//           <div className="col-lg-3 col-md-4 col-sm-6" key={chapter.id}>
//             <div className="card">
//               <img
//                 src={`https://nec.geoneer.com.np${chapter.chapterImage}`}
//                 className="card-img-top"
//                 alt={chapter.chapterName}
//                 style={{ height: '180px', borderRadius: '3%' }}
//               />
//               <div className="card-body">
//                 <h5 className="card-title">{chapter.chapterName}</h5>
              
//                 <div className="btn-group" role="group" aria-label="Basic example">
//                 <Button variant="success btn-sm" onClick={() => handleTakeExamChapterWise(chapter.chapterCode,chapter.id)}>
//                   {console.log(chapter.chapterCode)}
//                 Practice
//               </Button>
//                   <Button variant="primary  btn-sm" onClick={() => handleTakeExam(chapter.chapterCode,chapter.id)}>
//                   {console.log(chapter.chapterCode)}
//                   {console.log("chapter id is ",chapter.id)}
//                 Explore
//               </Button>
//               <Button variant="success btn-sm" onClick={() => handleTakeExamChapterWiseResult(chapter.chapterCode)}>
//                   {console.log(chapter.chapterCode)}
//                 View Results
//               </Button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>



//       <div className="row">
//         <h3> Second Paper Chapter Questions</h3>

//         {/* Display loading state */}
//         {loading && <p>Loading chapters...</p>}
//         {error && <p className="text-danger">Error: {error}</p>}

//         {/* Dynamically render chapters */}
//         {schapters.map((chapter) => (
//           <div className="col-lg-3 col-md-4 col-sm-6" key={chapter.id}>
//             <div className="card">
//               <img
//                 src={`https://nec.geoneer.com.np${chapter.chapterImage}`}
//                 className="card-img-top"
//                 alt={chapter.chapterName}
//                 style={{ height: '180px', borderRadius: '3%' }}
//               />
//               <div className="card-body">
//                 <h5 className="card-title">{chapter.chapterName}</h5>
              
//                 <div className="btn-group" role="group" aria-label="Basic example">
//                 <Button variant="success btn-sm" onClick={() => handleTakeExamSubChapterWise(chapter.chapterCode)}>
//                   {console.log(chapter.chapterCode)}
//                 Practice
//               </Button>
//                   <Button variant="primary  btn-sm" onClick={() => handleTakeExam(chapter.chapterCode,chapter.id)}>
//                   {/* {console.log(chapter.chapterCode)}
//                   {console.log("chapter id is ",chapter.id)} */}
//                 Explore
//               </Button>
//               <Button variant="success btn-sm" onClick={() => handleTakeExamChapterWiseResult(chapter.chapterCode)}>
//                   {/* {console.log(chapter.chapterCode)} */}
//                 View Results
//               </Button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>


//       {chapterCode !== null && (
//         <ChapterTestResults
//           chapterCode={chapterCode} 
          
//         />
//       )}


// {chapterCode !== null && (
//         <ChapterWiseSubjectiveExam
//           chapterCode={chapterCode} 
          
//         />
//       )}
//       {chapterCode !== null && (
//         <SubChapter
//           chapterCode={chapterCode} chapterId= {chapterId}
          
//         />
//       )}


// {chapterCode !== null && (
//         <ChaperWiseExam
//           chapterCode={chapterCode}
//           chapterId={chapterId}
          
          
//         />
//       )}




// {/* {courseCode !== null && (
//         <MockTestExam
//           courseCode={courseCode}
          
//         />
//       )} */}
//     </div>
//   );
// };

// export default CourseDetail;




// import React, { useState, useEffect } from 'react';
// import SubChapter from "./Sub-chapter";
// import { Button } from 'react-bootstrap';
// import { useHistory } from 'react-router-dom';
// import ChaperWiseExam from './exams/ChaperWiseExam';
// import mock from './images/MOCK TEST .png';
// import week from './images/weekly test.jpg';
// import ChapterTestResults from './results/ChapterTestResults';
// import ChapterWiseSubjectiveExam from './exams/ChapterWiseSubjectiveExam';

// const CourseDetail = () => {
//   const [chapters, setChapters] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [chapterCode, setChapterCode] = useState(null);
//   const [courseCode, setCourseCode] = useState('AMIN-PSC');
//   const [chapterId, setChapterId] = useState(null);
//   const [schapters, setSChapters] = useState([]);
//   const [userDetails, setUserDetails] = useState(null);

//   const history = useHistory();


//     useEffect(() => {
//       const storedUserDetails = localStorage.getItem("userDetails");
//       if (storedUserDetails) {
//         setUserDetails(JSON.parse(storedUserDetails));
//       }
//     }, []);

//   useEffect(() => {
//     const fetchChapters = async () => {
//       try {
//         const response = await fetch('https://nec.geoneer.com.np/api/exam/chapters/AMIN-PSC/');
//         if (!response.ok) {
//           throw new Error('Failed to fetch chapters');
//         }
//         const data = await response.json();
//         setChapters(data);
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchChapters();
//   }, []);

//   useEffect(() => {
//     const fetchSChapters = async () => {
//       try {
//         const response = await fetch('https://nec.geoneer.com.np/api/exam/schapters/SO-PSC/');
//         if (!response.ok) {
//           throw new Error('Failed to fetch chapters');
//         }
//         const data = await response.json();
//         setSChapters(data);
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchSChapters();
//   }, []);

//   const handleTakeExam = (chapterCode, chapterId) => {
//     setChapterCode(chapterCode);
//     setChapterId(chapterId);
//     history.push({
//       pathname: '/subchapters',
//       state: { chapterCode, chapterId },
//     });
//   };

//   const handleTakeExamChapterWise = (chapterCode, chapterId) => {
//     setChapterCode(chapterCode);
//     setChapterId(chapterId);
//     history.push({
//       pathname: '/chapterwise-exam',
//       state: { chapterCode, chapterId },
//     });
//   };

//   const handleTakeExamSubChapterWise = (chapterCode) => {
//     setChapterCode(chapterCode);
//     history.push({
//       pathname: '/chapterwise-subjective-exam',
//       state: { chapterCode },
//     });
//   };

//   const handleTakeExamChapterWiseResult = (chapterCode) => {
//     setChapterCode(chapterCode);
//     history.push({
//       pathname: '/chapter-test-results',
//       state: { chapterCode },
//     });
//   };

//   const handleMockExam = (courseCode) => {
//     setCourseCode(courseCode);
//     history.push({
//       pathname: '/mock-test',
//       state: { courseCode },
//     });
//   };

//   return (


   
//     <div className="container-fluid mt-12">
//       { console.log("coursecode is ", userDetails)}

//     { console.log("coursecode is ", userDetails.course.courseCode)}
//       <div className="row">
//         <h3 className="mb-4">Available Exams</h3>
//         <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
//           <div className="card h-100 shadow-sm">
//             <img src={mock} className="card-img-top" alt="Mock Test" style={{ height: "180px", objectFit: "cover" }} />
//             <div className="card-body d-flex flex-column">
//               {/* <h5 className="card-title">Mock Test</h5> */}
//               <p><bold>Mock Test</bold></p>

//               <div className="mt-auto">
//                 <Button variant="success btn-sm me-2" onClick={() => handleMockExam(courseCode)}>Take Exam</Button>
//                 <a href="/mock-test-results/" className="btn btn-primary btn-sm">View Results</a>
//               </div>
//             </div>
//           </div>
//         </div>

//         {['Weekly Test (I Paper)', 'Weekly Test (II Paper)'].map((title, index) => (
//           <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={index}>
//             <div className="card h-100 shadow-sm">
//               <img src={week} className="card-img-top" alt={title} style={{ height: "180px", objectFit: "cover" }} />
//               <div className="card-body d-flex flex-column">
//                 {/* <h5 className="card-title">{title}</h5> */}
//                 <p><bold>{title}</bold></p>
//                 <div className="mt-auto">
//                   <a href={index === 0 ? '/weekly-tests/' : '/sub-weekly-test/'} className="btn btn-success btn-sm me-2">View Tests</a>
//                   <a href='/weekly-test-results/' className="btn btn-primary btn-sm">View Results</a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>

//       <hr className="my-4" />

//       {/* {['First Paper Chapter and Subchapter wise Practice', 'Second Paper Chapter Questions'].map((title, index) => (
//         <div key={index}>
// <h1 className="text-2xl sm:text-3xl font-bold mb-4">{title}</h1>

//           {loading && <p>Loading chapters...</p>}
//           {error && <p className="text-danger">Error: {error}</p>}
//           <div className="row">
//             {(index === 0 ? chapters : schapters).map((chapter) => (
//               <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={chapter.id}>
//                 <div className="card h-100 shadow-sm">
//                   <img
//                     src={`https://nec.geoneer.com.np${chapter.chapterImage}`}
//                     className="card-img-top"
//                     alt={chapter.chapterName}
//                     style={{ height: '180px', objectFit: 'cover' }}
//                   />
//                   <div className="card-body d-flex flex-column">
//                     <h5 className="card-title">{chapter.chapterName}</h5>
//                     <div className="mt-auto">
//                       <Button 
//                         variant="success" 
//                         size="sm" 
//                         className="me-2"
//                         onClick={() => index === 0 ? handleTakeExamChapterWise(chapter.chapterCode, chapter.id) : handleTakeExamSubChapterWise(chapter.chapterCode)}
//                       >
//                         Practice
//                       </Button>
//                       <Button 
//                         variant="primary" 
//                         size="sm" 
//                         className="me-2"
//                         onClick={() => handleTakeExam(chapter.chapterCode, chapter.id)}
//                       >
//                         Explore
//                       </Button>
//                       <Button 
//                         variant="info" 
//                         size="sm"
//                         onClick={() => handleTakeExamChapterWiseResult(chapter.chapterCode)}
//                       >
//                         View Results
//                       </Button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       ))} */}


// {['First Paper Chapter and Subchapter wise Practice', 'Second Paper Chapter Questions'].map((title, index) => (
//   <div key={index}>
//     <h1 className="text-2xl sm:text-3xl font-bold mb-4">{title}</h1>

//     {loading && <p>Loading chapters...</p>}
//     {error && <p className="text-danger">Error: {error}</p>}
//     <div className="row">
//       {(index === 0 ? chapters : schapters).map((chapter) => (
//         <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={chapter.id}>
//           <div className="card h-100 shadow-sm">
//             <img
//               src={`https://nec.geoneer.com.np${chapter.chapterImage}`}
//               className="card-img-top"
//               alt={chapter.chapterName}
//               style={{ height: '180px', objectFit: 'cover' }}
//             />
//             <div className="card-body d-flex flex-column">
//               {/* <h5 className="card-title"></h5> */}
//               <p><bold>{chapter.chapterName}</bold></p>
//               <div className="mt-auto">
//                 <Button 
//                   variant="success" 
//                   size="sm" 
//                   className="me-2"
//                   onClick={() => index === 0 ? handleTakeExamChapterWise(chapter.chapterCode, chapter.id) : handleTakeExamSubChapterWise(chapter.chapterCode)}
//                 >
//                   Practice
//                 </Button>
//                 {index === 0 && (
//                   <>
//                     <Button 
//                       variant="primary" 
//                       size="sm" 
//                       className="me-2"
//                       onClick={() => handleTakeExam(chapter.chapterCode, chapter.id)}
//                     >
//                       Explore
//                     </Button>
//                     <Button 
//                       variant="info" 
//                       size="sm"
//                       onClick={() => handleTakeExamChapterWiseResult(chapter.chapterCode)}
//                     >
//                       View Results
//                     </Button>
//                   </>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   </div>
// ))}

//       {chapterCode && <ChapterTestResults chapterCode={chapterCode} />}
//       {chapterCode && <ChapterWiseSubjectiveExam chapterCode={chapterCode} />}
//       {chapterCode && <SubChapter chapterCode={chapterCode} chapterId={chapterId} />}
//       {chapterCode && <ChaperWiseExam chapterCode={chapterCode} chapterId={chapterId} />}
//     </div>
//   );
// };

// export default CourseDetail;



import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Button } from "react-bootstrap"
import mock from './images/MOCK TEST .png';
import week from './images/weekly test.jpg';

const CourseDetail = () => {
  const [chapters, setChapters] = useState([])
  const [schapters, setSChapters] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const [courseCode, setCourseCode] = useState(null)
  const [courseId,setCourseid]= useState(null)
 

  const history = useHistory()

  useEffect(() => {
    const storedUserDetails = localStorage.getItem("userDetails")
    if (storedUserDetails) {
      const parsedUserDetails = JSON.parse(storedUserDetails)
      setCourseCode(parsedUserDetails.course.courseCode)
      setCourseid(parsedUserDetails.course.id)
    }
  }, [])

  useEffect(() => {
    const fetchChapters = async () => {
      if (!courseCode) return
      try {
        const [chaptersResponse, schaptersResponse] = await Promise.all([
          fetch(`https://nec.geoneer.com.np/api/exam/chapters/${courseCode}/`),
          fetch(`https://nec.geoneer.com.np/api/exam/schapters/${courseCode}/`),
        ])

        if (!chaptersResponse.ok || !schaptersResponse.ok) {
          throw new Error("Failed to fetch chapters")
        }

        const chaptersData = await chaptersResponse.json()
        const schaptersData = await schaptersResponse.json()

        setChapters(chaptersData)
        setSChapters(schaptersData)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchChapters()
  }, [courseCode])

  const handleNavigation = (path, state) => {
    history.push({ pathname: path, state })
  }

  const ExamCard = ({ title, image, onTakeExam, onViewTests, onViewResults }) => (
    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
      <div className="card h-100 shadow-sm">
        <img
          src={image || "/placeholder.svg"}
          className="card-img-top"
          alt={title}
          style={{ height: "180px", objectFit: "cover" }}
        />
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">{title}</h5>
                    <p><bold>{title}</bold></p>
          <div className="mt-auto">
            {onTakeExam && (
              <Button variant="success" size="sm" className="me-2" onClick={onTakeExam}>
                Take Exam
              </Button>
            )}
            {onViewTests && (
              <Button variant="success" size="sm" className="me-2" onClick={onViewTests}>
                View Tests
              </Button>
            )}
            <Button variant="primary" size="sm" onClick={onViewResults}>
              View Results
            </Button>
          </div>
        </div>
      </div>
    </div>
  )

  const ChapterCard = ({ chapter, isSubjective, onPractice, onExplore, onViewResults }) => (
    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
      <div className="card h-100 shadow-sm">
        <img
          src={`https://nec.geoneer.com.np${chapter.chapterImage}`}
          className="card-img-top"
          alt={chapter.chapterName}
          style={{ height: "180px", objectFit: "cover" }}
        />
        <div className="card-body d-flex flex-column">
                   <p><bold>{chapter.chapterName}</bold></p>
          <div className="mt-auto">
            <Button variant="success" size="sm" className="me-2" onClick={onPractice}>
              Practice
            </Button>
            {!isSubjective && (
              <>
                <Button variant="primary" size="sm" className="me-2" onClick={onExplore}>
                  Explore
                </Button>
                <Button variant="info" size="sm" onClick={onViewResults}>
                  View Results
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )

  const renderExamCards = () => (
    <div className="row">
      <ExamCard
        title="Mock Test"
        image={mock}
        onTakeExam={() => handleNavigation("/mock-test", { courseCode ,  courseId})}
        onViewResults={() => handleNavigation("/mock-test-results",{ courseId})}
      />
      <ExamCard
        title="Weekly Test (I Paper)"
        image={week}
        onViewTests={() => handleNavigation("/weekly-tests")}
        onViewResults={() => handleNavigation("/weekly-test-results")}
      />
      {schapters.length > 0 && (
        <ExamCard
          title="Weekly Test (II Paper)"
          image={week}
          onViewTests={() => handleNavigation("/sub-weekly-test")}
          onViewResults={() => handleNavigation("/weekly-test-results")}
        />
      )}
    </div>
  )

  const renderChapters = (title, chapterList, isSubjective = false) => (
    <div>
      <h2 className="text-2xl sm:text-3xl font-bold mb-4">{title}</h2>
      {loading && <p>Loading chapters...</p>}
     
      {error && <p className="text-danger">Error: {error}</p>}
      {chapterList.length > 0 && (
        <div className="row">
          {chapterList.map((chapter) => (
            <ChapterCard
              key={chapter.id}
              chapter={chapter}
              isSubjective={isSubjective}
              onPractice={() =>
                handleNavigation(isSubjective ? "/chapterwise-subjective-exam" : "/chapterwise-exam", {
                  chapterCode: chapter.chapterCode,
                  chapterId: chapter.id,
                  courseCode,
                  courseId,
                  chapterName:chapter.chapterName,
                                 })
              }
              onExplore={() =>
                handleNavigation("/subchapters", { chapterCode: chapter.chapterCode, chapterId: chapter.id ,courseCode:courseCode,courseId:courseId,chapterName:chapter.chapterName})
              }
              onViewResults={() => handleNavigation("/chapter-test-results", { chapterCode: chapter.chapterCode,courseCode:courseCode })}
            />
          ))}
        </div>
      )}
    </div>
  )

  return (
    <div className="container-fluid mt-12">
      <h1 className="text-3xl font-bold mb-6">Available Exams</h1>
      {renderExamCards()}
      <hr className="my-8" />
      {renderChapters("Objective Chapter and Subchapter wise Practice", chapters)}
      {schapters.length > 0 && renderChapters("Second Paper Chapter Questions", schapters, true)}
    </div>
  )
}

export default CourseDetail

