// import {Route, Redirect} from "react-router-dom"
// import {useContext} from "react"
// import AuthContext from "../context/AuthContext"


// const PrivateRoute = ({children, ...rest}) => {
//     let {user} = useContext(AuthContext)
//     return <Route {...rest}>{!user ? <Redirect to="/login" /> : children}</Route>
// }

// export default PrivateRoute


// import { Route, Redirect } from "react-router-dom";
// import { useContext, useEffect, useState } from "react";
// import AuthContext from "../context/AuthContext";

// const PrivateRoute = ({ children, ...rest }) => {
//   const { user } = useContext(AuthContext);
//   const [isExpired, setIsExpired] = useState(false);
//   const {courseCode} = useState(null);

//   useEffect(() => {
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);

//       // Validity Expiry Check
//       const validityDate = new Date(parsedDetails?.validity || '');
//       const courseCode = parsedDetails?.courseCode;
     

//       const today = new Date();
//       if (validityDate < today) {
//         setIsExpired(true); // Set the flag if validity has expired
//       }
//     }
//   }, []);

//   if (!user) {
//     return <Redirect to="/login" />;
//   } else if (isExpired && rest.path !== "/profile") {
//     return <Redirect to="/profile" />;
//   }else if (courseCode == 'WTO'){
//     return <Redirect to ='/weekly-tests'/>
//         }
//   // Render the route if everything is fine
//   return <Route {...rest}>{children}</Route>;
// };

// export default PrivateRoute;
// import { Route, Redirect } from "react-router-dom";
// import { useContext, useEffect, useState } from "react";
// import AuthContext from "../context/AuthContext";

// const PrivateRoute = ({ children, ...rest }) => {
//   const { user } = useContext(AuthContext);
//   const [isExpired, setIsExpired] = useState(false);
//   const [courseCode, setCourseCode] = useState(null);

//   useEffect(() => {
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);

//       // Validity Expiry Check
//       const validityDate = new Date(parsedDetails?.validity || "");
//       const today = new Date();
//       if (validityDate < today) {
//         setIsExpired(true);
//         console.log("Validity expired");
//       }

//       // Set courseCode from user details
//       setCourseCode(parsedDetails?.course?.courseCode);
//       console.log("Course Code:", parsedDetails?.course?.courseCode);
//     }
//   }, []);

//   // Debug logs
//   console.log("User:", user);
//   console.log("isExpired:", isExpired);
//   console.log("courseCode:", courseCode);
//   console.log("Current path:", rest.path);

//   if (!user) {
//     return <Redirect to="/login" />;
//   } 

//   // Prioritize WTO course code restriction
  // if (courseCode === "WTO" && rest.path !== "/weekly-tests") {
  //   console.log("Redirecting to /weekly-tests for WTO course");
  //   return <Redirect to="/weekly-tests" />;
  // }

//   // Handle expired accounts
//   if (isExpired && rest.path !== "/profile") {
//     console.log("Redirecting to /profile due to expiration");
//     return <Redirect to="/profile" />;
//   }

//   // Render the route if all conditions pass
//   console.log("Rendering route:", rest.path);
//   return <Route {...rest}>{children}</Route>;
// };

// export default PrivateRoute;



// import { Route, Redirect } from "react-router-dom";
// import { useContext, useEffect, useState } from "react";
// import AuthContext from "../context/AuthContext";

// const PrivateRoute = ({ children, ...rest }) => {
//   const { user } = useContext(AuthContext);
//   const [isExpired, setIsExpired] = useState(false);
//   const [courseCode, setCourseCode] = useState(null);
//   const [weeklyTestOnly, setWeeklyTestOnly] = useState(false);

//   useEffect(() => {
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);

//       // Validity Expiry Check
//       const validityDate = new Date(parsedDetails?.validity || "");
//       const today = new Date();
//       if (validityDate < today) {
//         setIsExpired(true);
//         console.log("Validity expired");
//       }

//       // Set courseCode and weeklyTestOnly flag from user details
//       setCourseCode(parsedDetails?.course?.courseCode);
//       setWeeklyTestOnly(parsedDetails?.weekly_test_only || false);

//       console.log("Course Code:", parsedDetails?.course?.courseCode);
//       console.log("Weekly Test Only:", parsedDetails?.weekly_test_only);
//     }
//   }, []);

//   // Debug logs
//   console.log("User:", user);
//   console.log("isExpired:", isExpired);
//   console.log("courseCode:", courseCode);
//   console.log("weeklyTestOnly:", weeklyTestOnly);
//   console.log("Current path:", rest.path);

//   if (!user) {
//     return <Redirect to="/login" />;
//   } 

//   // Restrict to /weekly-tests if weekly_test_only is true
//   if (weeklyTestOnly && rest.path !== "/weekly-tests") {
//     console.log("Redirecting to /weekly-tests for weekly_test_only users");
//     return <Redirect to="/weekly-tests" />;
//   }

//   // Prioritize WTO course code restriction
//   if (courseCode === "WTO" && rest.path !== "/weekly-tests") {
//     console.log("Redirecting to /weekly-tests for WTO course");
//     return <Redirect to="/weekly-tests" />;
//   }

//   // Handle expired accounts
//   if (isExpired && rest.path !== "/profile") {
//     console.log("Redirecting to /profile due to expiration");
//     return <Redirect to="/profile" />;
//   }

//   // Render the route if all conditions pass
//   console.log("Rendering route:", rest.path);
//   return <Route {...rest}>{children}</Route>;
// };

// export default PrivateRoute;



// import { Route, Redirect } from "react-router-dom";
// import { useContext, useEffect, useState } from "react";
// import AuthContext from "../context/AuthContext";

// const PrivateRoute = ({ children, ...rest }) => {
//   const { user } = useContext(AuthContext);
//   const [isExpired, setIsExpired] = useState(false);
//   const [courseCode, setCourseCode] = useState(null);
//   const [weeklyTestOnly, setWeeklyTestOnly] = useState(false);

//   useEffect(() => {
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);

//       // Validity Expiry Check
//       const validityDate = new Date(parsedDetails?.validity || "");
//       const today = new Date();
//       if (validityDate < today) {
//         setIsExpired(true);
//         console.log("Validity expired");
//       }

//       // Set courseCode and weeklyTestOnly flag from user details
//       setCourseCode(parsedDetails?.course?.courseCode);
//       setWeeklyTestOnly(parsedDetails?.weekly_test_only || false);

//       console.log("Course Code:", parsedDetails?.course?.courseCode);
//       console.log("Weekly Test Only:", parsedDetails?.weekly_test_only);
//     }
//   }, []);

//   // Debug logs
//   console.log("User:", user);
//   console.log("isExpired:", isExpired);
//   console.log("courseCode:", courseCode);
//   console.log("weeklyTestOnly:", weeklyTestOnly);
//   console.log("Current path:", rest.path);

//   // List of allowed routes for weeklyTestOnly users
//   const allowedWeeklyTestRoutes = ["/weekly-tests", "/every-weekly-test-result", "/weekly-exam"];

//   if (!user) {
//     return <Redirect to="/login" />;
//   }

//   // Restrict to allowed routes if weeklyTestOnly is true
//   if (weeklyTestOnly && !allowedWeeklyTestRoutes.includes(rest.path)) {
//     console.log("Redirecting to /weekly-tests for weekly_test_only users");
//     return <Redirect to="/weekly-tests" />;
//   }

//   // Prioritize WTO course code restriction
//   if (courseCode === "WTO" && rest.path !== "/weekly-tests") {
//     console.log("Redirecting to /weekly-tests for WTO course");
//     return <Redirect to="/weekly-tests" />;
//   }

//   // Handle expired accounts
//   if (isExpired && rest.path !== "/profile") {
//     console.log("Redirecting to /profile due to expiration");
//     return <Redirect to="/profile" />;
//   }

//   // Render the route if all conditions pass
//   console.log("Rendering route:", rest.path);
//   return <Route {...rest}>{children}</Route>;
// };

// export default PrivateRoute;

// import { Route, Redirect } from "react-router-dom";
// import { useContext, useEffect, useState } from "react";
// import jwtDecode from "jwt-decode"; // Install this library using: npm install jwt-decode
// import AuthContext from "../context/AuthContext";

// const PrivateRoute = ({ children, ...rest }) => {
//   const { authTokens, user } = useContext(AuthContext);
//   const [isExpired, setIsExpired] = useState(false);
//   const [courseCode, setCourseCode] = useState(null);
//   const [weeklyTestOnly, setWeeklyTestOnly] = useState(false);


//   const [showMarquee, setShowMarquee] = useState(false);

//   useEffect(() => {
//     let timer;
//     if (showMarquee) {
//       timer = setTimeout(() => setShowMarquee(false), 5000); // Hide message after 5 seconds
//     }
//     return () => clearTimeout(timer);
//   }, [showMarquee]);


//   useEffect(() => {
//     // Decode the access token and check expiration
//     if (authTokens?.access) {
//       try {
//         const decodedToken = jwtDecode(authTokens.access);
//         const expiryTime = decodedToken.exp * 1000; // Convert to milliseconds
//         const currentTime = Date.now();

//         if (expiryTime < currentTime) {
//           setIsExpired(true);
//           console.log("Access token expired");
//         }
//       } catch (error) {
//         console.error("Error decoding token:", error);
//       }
//     }

//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);

//       // Set courseCode and weeklyTestOnly flag from user details
//       setCourseCode(parsedDetails?.course?.courseCode);
//       setWeeklyTestOnly(parsedDetails?.weekly_test_only || false);

//       console.log("Course Code:", parsedDetails?.course?.courseCode);
//       console.log("Weekly Test Only:", parsedDetails?.weekly_test_only);
//     }
//   }, [authTokens]);

//   // Debug logs
//   console.log("User:", user);
//   console.log("isExpired:", isExpired);
//   console.log("courseCode:", courseCode);
//   console.log("weeklyTestOnly:", weeklyTestOnly);
//   console.log("Current path:", rest.path);

//   // List of allowed routes for weeklyTestOnly users
//   const allowedWeeklyTestRoutes = ["/course/:courseName","/course-detail","/weekly-tests", "/every-weekly-test-result", "/weekly-exam","/weekly-test-sheet"];

//   if (!user || isExpired) {
//     console.log("Redirecting to /login due to no user or expired token");
//     return <Redirect to="/login" />;
//   }

//   // // Restrict to allowed routes if weeklyTestOnly is true
//   // if (weeklyTestOnly && !allowedWeeklyTestRoutes.includes(rest.path)) {
//   //   console.log("Redirecting to /weekly-tests for weekly_test_only users");
//   //   return <Redirect to="/course-detail" />;
//   // }
//     // Restrict to allowed routes if weeklyTestOnly is true
//   if (weeklyTestOnly && !allowedWeeklyTestRoutes.includes(rest.path)) {
//     console.log("Redirecting to /course-detail for weekly_test_only users");
//     setShowMarquee(true); // Show the marquee message
//     return <Redirect to="/course-detail" />;
//   }


//   // Prioritize WTO course code restriction
//   if (courseCode === "WTO" && rest.path !== "/weekly-tests") {
//     console.log("Redirecting to /weekly-tests for WTO course");
//     return <Redirect to="/weekly-tests" />;
//   }

//   // Render the route if all conditions pass
//   console.log("Rendering route:", rest.path);
//   return <Route {...rest}>{children}</Route>;
// };

// export default PrivateRoute;




// import { useContext, useEffect, useState, useCallback } from "react"
// import { Route, Redirect, useLocation } from "react-router-dom"
// import jwtDecode from "jwt-decode"
// import AuthContext from "../context/AuthContext"

// const allowedWeeklyTestRoutes = [
//   "/course/:courseName",
//   "/course-detail",
//   "/weekly-tests",
//   "/every-weekly-test-result",
//   "/weekly-exam",
//   "/weekly-test-sheet",
//   "/weekly-subjective-exam",
//   "/weekly-subjective-exam-result",
//   "/weekly-subjective-checked-answer",
//   "/sub-weekly-test",
//   "/weekly-test-results",

// ]

// const PrivateRoute = ({ children, ...rest }) => {
//   const { authTokens, user } = useContext(AuthContext)
//   const [isExpired, setIsExpired] = useState(false)
//   const [courseCode, setCourseCode] = useState(null)
//   const [weeklyTestOnly, setWeeklyTestOnly] = useState(false)
//   const [showMarquee, setShowMarquee] = useState(false)
//   const location = useLocation()

//   const checkTokenExpiration = useCallback(() => {
//     if (authTokens?.access) {
//       try {
//         const decodedToken = jwtDecode(authTokens.access)
//         const expiryTime = decodedToken.exp * 1000
//         const currentTime = Date.now()

//         if (expiryTime < currentTime) {
//           setIsExpired(true)
//           console.log("Access token expired")
//         }
//       } catch (error) {
//         console.error("Error decoding token:", error)
//       }
//     }
//   }, [authTokens])

//   const getUserDetails = useCallback(() => {
//     const storedUserDetails = localStorage.getItem("userDetails")
//   //   if (storedUserDetails) {
//   //     const parsedDetails = JSON.parse(storedUserDetails)
//   //     setCourseCode(parsedDetails?.course?.courseCode)
//   //     setWeeklyTestOnly(parsedDetails?.weekly_test_only || false)
//   //     console.log("Course Code:", parsedDetails?.course?.courseCode)
//   //     console.log("Weekly Test Only:", parsedDetails?.weekly_test_only)
//   //   }
//   // }, [])
//   //     if (storedUserDetails) {
// //       const parsedDetails = JSON.parse(storedUserDetails);

// //       // Validity Expiry Check
// //       const validityDate = new Date(parsedDetails?.validity || "");
// //       const today = new Date();
// //       if (validityDate < today) {
// //         setIsExpired(true);
// //         console.log("Validity expired");
// //       }

// //       // Set courseCode and weeklyTestOnly flag from user details
// //       setCourseCode(parsedDetails?.course?.courseCode);
// //       setWeeklyTestOnly(parsedDetails?.weekly_test_only || false);

// //       console.log("Course Code:", parsedDetails?.course?.courseCode);
// //       console.log("Weekly Test Only:", parsedDetails?.weekly_test_only);
// //     }
// //   }, []);

//   useEffect(() => {
//     checkTokenExpiration()
//     getUserDetails()
//   }, [checkTokenExpiration, getUserDetails])

//   useEffect(() => {
//     let timer
//     if (showMarquee) {
//       timer = setTimeout(() => setShowMarquee(false), 5000)
//     }
//     return () => clearTimeout(timer)
//   }, [showMarquee])

//   // Debug logs
//   useEffect(() => {
//     console.log("User:", user)
//     console.log("isExpired:", isExpired)
//     console.log("courseCode:", courseCode)
//     console.log("weeklyTestOnly:", weeklyTestOnly)
//     console.log("Current path:", location.pathname)
//   }, [user, isExpired, courseCode, weeklyTestOnly, location.pathname])

//   if (!user || isExpired) {
//     console.log("Redirecting to /login due to no user or expired token")
//     return <Redirect to="/login" />
//   }
//     // Handle expired accounts
//   if (isExpired && rest.path !== "/profile") {
//     console.log("Redirecting to /profile due to expiration");
//     return <Redirect to="/profile" />;
//   }

//   if ( weeklyTestOnly && !allowedWeeklyTestRoutes.includes(location.pathname)) {
//     console.log("Redirecting to /course-detail for weekly_test_only users")
//     if (!showMarquee) {
//       setShowMarquee(true)
//     }
//     return <Redirect to="/course-detail" />
//   }

//   if (courseCode === "WTO" && location.pathname !== "/weekly-tests") {
//     console.log("Redirecting to /weekly-tests for WTO course")
//     return <Redirect to="/weekly-tests" />
//   }

//   console.log("Rendering route:", location.pathname)


//   // const marqueeContainerStyle = {
//   //   position: "fixed",
//   //   top: "50%",
//   //   left: "50%",
//   //   transform: "translate(-50%, -50%)",
//   //   width: "100%",
//   //   maxWidth: "600px",
//   //   backgroundColor: "rgba(255, 255, 0, 0.9)",
//   //   padding: "20px",
//   //   borderRadius: "10px",
//   //   boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
//   //   zIndex: 1000,
//   //   overflow: "hidden",
//   // }

//   // const marqueeContentStyle = {
//   //   display: "inline-block",
//   //   whiteSpace: "nowrap",
//   //   animation: "marquee 15s linear infinite",
//   // }

//   // const marqueeTextStyle = {
//   //   fontWeight: "bold",
//   //   fontSize: "1.2rem",
//   //   color: "#333",
//   // }

//   const marqueeContainerStyle = {
//     position: "fixed",
//     bottom: "20px",
//     left: "50%",
//     transform: "translateX(-50%)",
//     width: "auto",
//     maxWidth: "90%",
//     backgroundColor: "rgba(255, 255, 0, 0.9)",
//     padding: "10px 20px",
//     borderRadius: "8px",
//     boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
//     zIndex: 1000,
//     overflow: "hidden",
//     textAlign: "center",
//   };
  
//   const marqueeContentStyle = {
//     display: "inline-block",
//     whiteSpace: "nowrap",
//     animation: "marquee 10s linear infinite",
//   };
  
//   const marqueeTextStyle = {
//     fontWeight: "bold",
//     fontSize: "1rem",
//     color: "#333",
//   };
  
//   return (
// <>
//       {showMarquee && (
//         <div style={marqueeContainerStyle}>
//           <div style={marqueeContentStyle}>
//             <span style={marqueeTextStyle}>Please subscribe to the complete course if you want to explore more.</span>
//           </div>
//         </div>
//       )}
//       <Route {...rest}>{children}</Route>
//       <style>
//         {`
//           @keyframes marquee {
//             0% { transform: translateX(100%); }
//             100% { transform: translateX(-100%); }
//           }
//         `}
//       </style>
//     </>
//   )
// }

// export default PrivateRoute





// import { useContext, useEffect, useState, useCallback } from "react"
// import { Route, Redirect, useLocation } from "react-router-dom"
// import jwtDecode from "jwt-decode"
// import AuthContext from "../context/AuthContext"

// const allowedWeeklyTestRoutes = [
//   "/course/:courseName",
//   "/course-detail",
//   "/weekly-tests",
//   "/every-weekly-test-result",
//   "/weekly-exam",
//   "/weekly-test-sheet",
//   "/weekly-subjective-exam",
//   "/weekly-subjective-exam-result",
//   "/weekly-subjective-checked-answer",
//   "/sub-weekly-test",
//   "/weekly-test-results",

// ]

// const PrivateRoute = ({ children, ...rest }) => {
//   const { authTokens, user } = useContext(AuthContext)
//   const [isExpired, setIsExpired] = useState(false)
//   const [courseCode, setCourseCode] = useState(null)
//   const [weeklyTestOnly, setWeeklyTestOnly] = useState(false)
//   const [showMarquee, setShowMarquee] = useState(false)
//   const [isVExpired, setIsVExpired] = useState(false)

  
//   const location = useLocation()
  

//   const checkTokenExpiration = useCallback(() => {
//     if (authTokens?.access) {
//       try {
//         const decodedToken = jwtDecode(authTokens.access)
//         const expiryTime = decodedToken.exp * 1000
//         const currentTime = Date.now()

//         if (expiryTime < currentTime) {
//           setIsExpired(true)
//           console.log("Access token expired")
//         }
//       } catch (error) {
//         console.error("Error decoding token:", error)
//       }
//     }
//   }, [authTokens])
  


//   const getUserDetails = useCallback(() => {
//     const storedUserDetails = localStorage.getItem("userDetails")
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails)
//       setCourseCode(parsedDetails?.course?.courseCode)
//       setWeeklyTestOnly(parsedDetails?.weekly_test_only || false)
//       console.log("Course Code:", parsedDetails?.course?.courseCode)
//       console.log("Weekly Test Only:", parsedDetails?.weekly_test_only)
//     }
//   }, [])

//     useEffect(() => {
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);

//       // Validity Expiry Check
//       const validityDate = new Date(parsedDetails?.validity || "");
//       const today = new Date();
//       if (validityDate < today) {
//         setIsVExpired(true);
//         console.log("Validity expired");
//       }

//       // Set courseCode and weeklyTestOnly flag from user details
//       setCourseCode(parsedDetails?.course?.courseCode);
//       setWeeklyTestOnly(parsedDetails?.weekly_test_only || false);

//       console.log("Course Code:", parsedDetails?.course?.courseCode);
//       console.log("Weekly Test Only:", parsedDetails?.weekly_test_only);
//     }
//   }, []);

//   useEffect(() => {
//     checkTokenExpiration()
//     getUserDetails()
//   }, [checkTokenExpiration, getUserDetails])

//   useEffect(() => {
//     let timer
//     if (showMarquee) {
//       timer = setTimeout(() => setShowMarquee(false), 5000)
//     }
//     return () => clearTimeout(timer)
//   }, [showMarquee])

//   // Debug logs
//   useEffect(() => {
//     console.log("User:", user)
//     console.log("isExpired:", isExpired)
//     console.log("courseCode:", courseCode)
//     console.log("weeklyTestOnly:", weeklyTestOnly)
//     console.log("Current path:", location.pathname)
//   }, [user, isExpired, courseCode, weeklyTestOnly, location.pathname])

//   if (!user || isExpired) {
//     console.log("Redirecting to /login due to no user or expired token")
//     return <Redirect to="/login" />
//   }
//   if (isVExpired && rest.path !== "/profile") {
//         return <Redirect to="/profile" />;
//       }

//   if (weeklyTestOnly && !allowedWeeklyTestRoutes.includes(location.pathname)) {
//     console.log("Redirecting to /course-detail for weekly_test_only users")
//     if (!showMarquee) {
//       setShowMarquee(true)
//     }
//     return <Redirect to="/course-detail" />
//   }

//   if (courseCode === "WTO" && location.pathname !== "/weekly-tests") {
//     console.log("Redirecting to /weekly-tests for WTO course")
//     return <Redirect to="/weekly-tests" />
//   }

//   console.log("Rendering route:", location.pathname)


//   const marqueeContainerStyle = {
//     position: "fixed",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: "100%",
//     maxWidth: "600px",
//     backgroundColor: "rgba(255, 255, 0, 0.9)",
//     padding: "20px",
//     borderRadius: "10px",
//     boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
//     zIndex: 1000,
//     overflow: "hidden",
//   }

//   const marqueeContentStyle = {
//     display: "inline-block",
//     whiteSpace: "nowrap",
//     animation: "marquee 15s linear infinite",
//   }

//   const marqueeTextStyle = {
//     fontWeight: "bold",
//     fontSize: "1.2rem",
//     color: "#333",
//   }

//   return (
// <>
//       {showMarquee && (
//         <div style={marqueeContainerStyle}>
//           <div style={marqueeContentStyle}>
//             <span style={marqueeTextStyle}>Please subscribe to the complete course if you want to explore more.</span>
//           </div>
//         </div>
//       )}
//       <Route {...rest}>{children}</Route>
//       <style>
//         {`
//           @keyframes marquee {
//             0% { transform: translateX(100%); }
//             100% { transform: translateX(-100%); }
//           }
//         `}
//       </style>
//     </>
//   )
// }

// export default PrivateRoute

import { useContext, useEffect, useState, useCallback } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import AuthContext from "../context/AuthContext";

const allowedWeeklyTestRoutes = [
  "/course/",
  "/course-detail",
  "/weekly-tests",
  "/every-weekly-test-result",
  "/weekly-exam",
  "/weekly-test-sheet",
  "/weekly-subjective-exam",
  "/weekly-subjective-exam-result",
  "/weekly-subjective-checked-answer",
  "/sub-weekly-test",
  "/weekly-test-results",
  "/profile",
  "/Syllabus"
];

const PrivateRoute = ({ children, ...rest }) => {
  const { authTokens, user } = useContext(AuthContext);
  const [isExpired, setIsExpired] = useState(false);
  // const [courseCode, setCourseCode] = useState(null);
  const [weeklyTestOnly, setWeeklyTestOnly] = useState(false);
  const [showMarquee, setShowMarquee] = useState(false);
  const [isVExpired, setIsVExpired] = useState(false);

  const location = useLocation();

  const checkTokenExpiration = useCallback(() => {
    if (authTokens?.access) {
      try {
        const decodedToken = jwtDecode(authTokens.access);
        const expiryTime = decodedToken.exp * 1000;
        if (expiryTime < Date.now() && !isExpired) {  // Only update if expired and not already marked
          setIsExpired(true);
          console.log("Access token expired");
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [authTokens, isExpired]);  // Add isExpired to prevent redundant updates

  useEffect(() => {
    const storedUserDetails = localStorage.getItem("userDetails");
    if (storedUserDetails) {
      const parsedDetails = JSON.parse(storedUserDetails);
      // setCourseCode(parsedDetails?.course?.courseCode || null);
      setWeeklyTestOnly(parsedDetails?.weekly_test_only || false);

      const validityDate = new Date(parsedDetails?.validity || "");
      if (validityDate < new Date() && !isVExpired) {  // Only update if expired and not already marked
        setIsVExpired(true);
        console.log("Validity expired");
      }
    }
  }, [isVExpired]);  // Add isVExpired to prevent redundant updates

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  useEffect(() => {
    if (showMarquee) {
      const timer = setTimeout(() => setShowMarquee(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showMarquee]);

  if (!user || isExpired) {
    console.log("Redirecting to /login due to no user or expired token");
    return <Redirect to="/login" />;
  }

  if (isVExpired && rest.path !== "/profile") {
    return <Redirect to="/profile" />;
  }



    if (weeklyTestOnly && !allowedWeeklyTestRoutes.includes(location.pathname)) {
    console.log("Redirecting to /course-detail for weekly_test_only users")
    if (!showMarquee) {
      setShowMarquee(true)
    }
    return <Redirect to="/course-detail" />
  }




  const marqueeContainerStyle = {
    position: "fixed",
    top: "50px",  // Positioned at the top of the screen
    right: "20px",  // Positioned at the right of the screen
    
    maxWidth: "600px",
    width: "100%",
    backgroundColor: "rgba(255, 0, 0, 0.9)",  // Red background for alert
    padding: "15px 25px",
    borderRadius: "30px",  // Rounded corners
    boxShadow: "0 6px 10px rgba(0, 0, 0, 0.1)",  // Slight shadow for depth
    zIndex: 1000,
    fontFamily: "'Helvetica Neue', sans-serif",  // Modern font
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  
  const marqueeContentStyle = {
    display: "inline-block",
    whiteSpace: "normal",  // No scrolling, just display the message
  };
  
  const marqueeTextStyle = {
    fontWeight: "600",  // Semi-bold for emphasis
    fontSize: "1.3rem",  // Slightly larger font size
    color: "#fff",  // White text for better contrast
  };
  




  return(
    <>
      {showMarquee && (
        <div style={marqueeContainerStyle}>
          <div style={marqueeContentStyle}>
            <span style={marqueeTextStyle}>Please purchase   the complete course if you want to explore more. Only weekly tests are available to you</span>
          </div>
        </div>
      )}
      <Route {...rest}>{children}</Route>
      <style>
        {`
          @keyframes marquee {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }
        `}
      </style>
    </>
  )
  
  //  <Route {...rest}>{children}</Route>;
};

export default PrivateRoute;
