
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import logo from './logo.png';
import userImage from './R.png';
import { motion, AnimatePresence } from 'framer-motion';

function Navbar() {
  const { logoutUser } = useContext(AuthContext);
  const token = localStorage.getItem('authTokens');
  const [menuOpen, setMenuOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const storedUserDetails = localStorage.getItem("userDetails");
    if (storedUserDetails) {
      const parsedDetails = JSON.parse(storedUserDetails);
      setUserDetails(parsedDetails);

      const validityDate = new Date(parsedDetails?.validity || '');
      const today = new Date();
      if (validityDate < today) {
        setIsExpired(true);
      }
    }
  }, []);

  // const courseCode = userDetails?.course?.courseCode;

  // const links = {
  //   'SO-PSC': [
  //     { path: '/', label: 'Home' },
  //     { path: '/course/:courseName', label: 'Dashboard' },
  //     { path: '/profile', label: 'Profile' },
  //   ],
  //   'AMIN-PSC': [
  //     { path: '/', label: 'Home' },
  //     { path: '/course/:courseName', label: 'Dashboard' },
  //     { path: '/profile', label: 'Profile' },
  //   ],
  //   'ACiE': [
  //     { path: '/', label: 'Home' },
  //     { path: '/course/:courseName', label: 'Dashboard' },
  //     { path: '/profile', label: 'Profile' },
  //   ],
  //   'ACE': [
  //     { path: '/', label: 'Home' },
  //     { path: '/course/:courseName', label: 'Dashboard' },
  //     { path: '/profile', label: 'Profile' },
  //   ],
  //   'AGeE': [
  //     { path: '/', label: 'Home' },
  //     { path: '/chapterlist', label: 'Chapter Wise' },
  //     { path: '/rquiz', label: 'License Practice Exam' },
  //     { path: '/profile', label: 'Profile' },
  //   ],
  // };
  const links = [
        { path: '/', label: 'Home' },
      { path: '/course/:courseName', label: 'Dashboard' },
      { path: '/profile', label: 'Profile' },
      { path: '/Syllabus', label: 'Syllabus' },
      
  ]

  const closeMenu = () => setMenuOpen(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) closeMenu();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <nav className="bg-white shadow-lg fixed top-0 left-0 right-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <Link to="/" className="flex items-center group">
              <img className="h-10 w-auto transition-transform duration-300 ease-in-out group-hover:scale-110" src={logo} alt="Logo" />
              {/* <span className="ml-2 text-xl font-bold text-gray-800 transition-colors duration-300 ease-in-out group-hover:text-green-600">Examneer</span> */}
            </Link>
          </div>
          <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
            {token === null ? (
              <>
                <NavLink to="/">Home</NavLink>
                <NavLink to="/syallabus">Syllabus</NavLink>
                <NavLink to="/register">Register</NavLink>
                <NavLink to="/login">Login</NavLink>
            

              </>
            ) : (
              <>
                {links?.map((link, index) => (
                  <NavLink
                    key={index}
                    to={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? '#' : link.path}
                    disabled={isExpired && link.label !== 'Home' && link.label !== 'Profile'}
                    onClick={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? (e) => e.preventDefault() : closeMenu}
                  >
                    {link.label}
                  </NavLink>
                ))}
              </>
            )}
          </div>
          <div className="flex items-center">
            {token !== null && (
              <div className="hidden lg:flex lg:items-center">
                <button
                  onClick={logoutUser}
                  className="text-gray-600 hover:bg-green-100 hover:text-green-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out"
                >
                  Logout
                </button>
                <div className="ml-3 relative flex items-center">
                  <span className="text-gray-700 text-sm mr-2">Hello, {userDetails?.first_name}!</span>
                  <motion.img
                    whileHover={{ scale: 1.1 }}
                    className="h-10 w-10 rounded-full object-cover border-2 border-green-500"
                    src={userImage}
                    alt="User avatar"
                  />
                </div>
              </div>
            )}
            <div className="flex items-center lg:hidden">
              <button
                onClick={() => setMenuOpen(!menuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 transition duration-300 ease-in-out"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <motion.svg
                  animate={menuOpen ? "open" : "closed"}
                  variants={{
                    open: { rotate: 180 },
                    closed: { rotate: 0 }
                  }}
                  transition={{ duration: 0.3 }}
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </motion.svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="lg:hidden"
          >
            <div className="px-2 pt-2 pb-3 space-y-1 bg-white shadow-lg rounded-b-lg">
              {token === null ? (
                <>
                  <MobileNavLink to="/" onClick={closeMenu}>Home</MobileNavLink>
                  <MobileNavLink to="/syallabus" onClick={closeMenu}>Syllabus</MobileNavLink>
                  
                  <MobileNavLink to="/login" onClick={closeMenu}>Login</MobileNavLink>
                  <MobileNavLink to="/register" onClick={closeMenu}>Register</MobileNavLink>
                </>
              ) : (
                <>
                  {links?.map((link, index) => (
                    <MobileNavLink
                      key={index}
                      to={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? '#' : link.path}
                      disabled={isExpired && link.label !== 'Home' && link.label !== 'Profile'}
                      onClick={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? (e) => e.preventDefault() : closeMenu}
                    >
                      {link.label}
                    </MobileNavLink>
                  ))}
                  <button
                    onClick={() => {
                      logoutUser();
                      closeMenu();
                    }}
                    className="text-gray-600 hover:bg-green-100 hover:text-green-700 block px-3 py-2 rounded-md text-base font-medium w-full text-left transition duration-300 ease-in-out"
                  >
                    Logout
                  </button>
                  <div className="pt-4 pb-3 border-t border-gray-200">
                    <div className="flex items-center px-4">
                      <div className="flex-shrink-0">
                        <motion.img whileHover={{ scale: 1.1 }} className="h-10 w-10 rounded-full object-cover border-2 border-green-500" src={userImage} alt="User avatar" />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-gray-800">Hello, {userDetails?.first_name}!</div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}

const NavLink = ({ children, ...props }) => (
  <Link
    className="text-gray-600 hover:bg-green-100 hover:text-green-700 inline-flex items-center px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out"
    {...props}
  >
    {children}
  </Link>
);

const MobileNavLink = ({ children, ...props }) => (
  <Link
    className="text-gray-600 hover:bg-green-100 hover:text-green-700 block px-3 py-2 rounded-md text-base font-medium transition duration-300 ease-in-out"
    {...props}
  >
    {children}
  </Link>
);

export default Navbar;



// import React, { useContext, useState, useEffect } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import AuthContext from '../context/AuthContext';
// import logo from './logo.png';
// import userImage from './R.png';
// import { motion, AnimatePresence } from 'framer-motion';

// function Navbar() {
//   const { logoutUser } = useContext(AuthContext);
//   const [token, setToken] = useState(localStorage.getItem('authTokens'));
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [userDetails, setUserDetails] = useState(null);
//   const [isExpired, setIsExpired] = useState(false);
//   const location = useLocation();

//   useEffect(() => {
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);
//       setUserDetails(parsedDetails);

//       const validityDate = new Date(parsedDetails?.validity || '');
//       const today = new Date();
//       if (validityDate < today) {
//         setIsExpired(true);
//       }
//     }

//     const handleStorageChange = () => {
//       setToken(localStorage.getItem('authTokens'));
//       const updatedUserDetails = localStorage.getItem("userDetails");
//       if (updatedUserDetails) {
//         setUserDetails(JSON.parse(updatedUserDetails));
//       }
//     };

//     window.addEventListener('storage', handleStorageChange);
//     return () => window.removeEventListener('storage', handleStorageChange);
//   }, []);

//   useEffect(() => {
//     setToken(localStorage.getItem('authTokens'));
//   }, [location]);

//   const courseCode = userDetails?.course?.courseCode;

//   const links = {
//     'SO-PSC': [
//       { path: '/', label: 'Home' },
//       { path: '/course/:courseName', label: 'Dashboard' },
//       { path: '/profile', label: 'Profile' },
//     ],
//     'AMIN-PSC': [
//       { path: '/', label: 'Home' },
//       { path: '/course/:courseName', label: 'Dashboard' },
//       { path: '/profile', label: 'Profile' },
//     ],
//     'AGeE': [
//       { path: '/', label: 'Home' },
//       { path: '/chapterlist', label: 'Chapter Wise' },
//       { path: '/rquiz', label: 'License Practice Exam' },
//       { path: '/profile', label: 'Profile' },
//     ],
//   };

//   const closeMenu = () => setMenuOpen(false);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 1024) closeMenu();
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   return (
//     <nav className="bg-white shadow-lg fixed top-0 left-0 right-0 z-50">
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="flex justify-between h-16">
//           <div className="flex-shrink-0 flex items-center">
//             <Link to="/" className="flex items-center group">
//               <motion.img 
//                 whileHover={{ scale: 1.1 }}
//                 className="h-10 w-auto transition-transform duration-300 ease-in-out"
//                 src={logo}
//                 alt="Logo"
//               />
        
//             </Link>
//           </div>
//           <div className="hidden lg:flex lg:items-center lg:space-x-4">
//             {token === null ? (
//               <>
//                 <NavLink to="/">Home</NavLink>
//                 <NavLink to="/login">Login</NavLink>
//                 <NavLink to="/register">Register</NavLink>
//               </>
//             ) : (
//               <>
//                 {links[courseCode]?.map((link, index) => (
//                   <NavLink
//                     key={index}
//                     to={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? '#' : link.path}
//                     disabled={isExpired && link.label !== 'Home' && link.label !== 'Profile'}
//                     onClick={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? (e) => e.preventDefault() : closeMenu}
//                   >
//                     {link.label}
//                   </NavLink>
//                 ))}
//                 <motion.button
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                   onClick={logoutUser}
//                   className="text-gray-600 hover:bg-green-100 hover:text-green-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out"
//                 >
//                   Logout
//                 </motion.button>
//                 {token !== null && (
//                   <div className="flex items-center">
//                     <motion.span
//                       initial={{ opacity: 0, y: -10 }}
//                       animate={{ opacity: 1, y: 0 }}
//                       transition={{ duration: 0.5 }}
//                       className="text-gray-700 text-sm mr-2"
//                     >
//                       Hello, {userDetails?.first_name}!
//                     </motion.span>
//                     <motion.img
//                       whileHover={{ scale: 1.1 }}
//                       className="h-10 w-10 rounded-full object-cover border-2 border-green-500"
//                       src={userImage}
//                       alt="User avatar"
//                     />
//                   </div>
//                 )}
//               </>
//             )}
//           </div>
//           <div className="flex items-center">
//             <div className="flex items-center lg:hidden">
//               <motion.button
//                 whileTap={{ scale: 0.95 }}
//                 onClick={() => setMenuOpen(!menuOpen)}
//                 className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 transition duration-300 ease-in-out"
//                 aria-expanded="false"
//               >
//                 <span className="sr-only">Open main menu</span>
//                 <motion.svg
//                   animate={menuOpen ? "open" : "closed"}
//                   variants={{
//                     open: { rotate: 180 },
//                     closed: { rotate: 0 }
//                   }}
//                   transition={{ duration: 0.3 }}
//                   className="block h-6 w-6"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke="currentColor"
//                   aria-hidden="true"
//                 >
//                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
//                 </motion.svg>
//               </motion.button>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Mobile menu */}
//       <AnimatePresence>
//         {menuOpen && (
//           <motion.div
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.3 }}
//             className="lg:hidden"
//           >
//             <div className="px-2 pt-2 pb-3 space-y-1 bg-white shadow-lg rounded-b-lg">
//               {token === null ? (
//                 <>
//                   <MobileNavLink to="/" onClick={closeMenu}>Home</MobileNavLink>
//                   <MobileNavLink to="/login" onClick={closeMenu}>Login</MobileNavLink>
//                   <MobileNavLink to="/register" onClick={closeMenu}>Register</MobileNavLink>
//                 </>
//               ) : (
//                 <>
//                   {links[courseCode]?.map((link, index) => (
//                     <MobileNavLink
//                       key={index}
//                       to={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? '#' : link.path}
//                       disabled={isExpired && link.label !== 'Home' && link.label !== 'Profile'}
//                       onClick={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? (e) => e.preventDefault() : closeMenu}
//                     >
//                       {link.label}
//                     </MobileNavLink>
//                   ))}
//                   <motion.button
//                     whileTap={{ scale: 0.95 }}
//                     onClick={() => {
//                       logoutUser();
//                       closeMenu();
//                     }}
//                     className="text-gray-600 hover:bg-green-100 hover:text-green-700 block px-3 py-2 rounded-md text-base font-medium w-full text-left transition duration-300 ease-in-out"
//                   >
//                     Logout
//                   </motion.button>
//                   <div className="pt-4 pb-3 border-t border-gray-200">
//                     <div className="flex items-center px-4">
//                       <div className="flex-shrink-0">
//                         <motion.img whileHover={{ scale: 1.1 }} className="h-10 w-10 rounded-full object-cover border-2 border-green-500" src={userImage} alt="User avatar" />
//                       </div>
//                       <div className="ml-3">
//                         <motion.div
//                           initial={{ opacity: 0, y: -10 }}
//                           animate={{ opacity: 1, y: 0 }}
//                           transition={{ duration: 0.5 }}
//                           className="text-base font-medium text-gray-800"
//                         >
//                           Hello, {userDetails?.first_name}!
//                         </motion.div>
//                       </div>
//                     </div>
//                   </div>
//                 </>
//               )}
//             </div>
//           </motion.div>
//         )}
//       </AnimatePresence>
//     </nav>
//   );
// }

// const NavLink = ({ children, ...props }) => (
//   <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
//     <Link
//       className="text-gray-600 hover:bg-green-100 hover:text-green-700 inline-flex items-center px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out"
//       {...props}
//     >
//       {children}
//     </Link>
//   </motion.div>
// );

// const MobileNavLink = ({ children, ...props }) => (
//   <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
//     <Link
//       className="text-gray-600 hover:bg-green-100 hover:text-green-700 block px-3 py-2 rounded-md text-base font-medium transition duration-300 ease-in-out"
//       {...props}
//     >
//       {children}
//     </Link>
//   </motion.div>
// );

// export default Navbar;







