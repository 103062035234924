// import React, { useState, useEffect, useRef,useContext} from 'react';
// import axios from 'axios';
// import '../../components/quiz.css';
// import Swal from 'sweetalert2';
// import parse from 'html-react-parser';
// import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import AuthContext from '../../context/AuthContext';


// const SubChapterWiseExam = (props) => {
//   const [error, setError] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [items, setItems] = useState([]);
//   const [selectedAnswers, setSelectedAnswers] = useState({});
//   const questionRef = useRef(null);
//   const [showResultButton, setShowResultButton] = useState(false);
//   const [optionStyle, setOptionStyle] = useState({});
//   const [showResultDetails, setShowResultDetails] = useState(false);
//   const [disabledQuestions, setDisabledQuestions] = useState([]);
//   let questionNumber = 1; 
//   const [submissionResponse, setSubmissionResponse] = useState(null);

//   const location = useLocation();
//   const chapterCode = location.state?.chapterCode;
//   const chapterId = location.state?.chapterId;
//   const subChapterCode = location.state?.subChapterCode;
//   const subChapterId = location.state?.subChapterId;
//   const courseCode = location.state?.courseCode;
//   const courseId = location.state?.courseId;

  

//   const { authTokens, user } = useContext(AuthContext);



//   console.log(chapterCode);

//   const handleResultSubmit = (e) => {
//     e.preventDefault(); // Prevent default form submission
//     setShowResultButton(true);
//     setDisabledQuestions(Object.keys(selectedAnswers));
//   };

//   useEffect(() => {
//     const handleBeforeUnload = (e) => {
//       if (!showResultButton) {
//         e.preventDefault();
//         e.returnValue = '';
//       }
//     };
  
//     window.addEventListener('beforeunload', handleBeforeUnload);
  
//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [showResultButton]);

  
  

//   useEffect(() => {
//     if (chapterCode && subChapterCode && courseCode ) {
//       axios
//       .get(` https://nec.geoneer.com.np/api/exam/questions/${courseCode}/${chapterCode}/${subChapterCode}/ `)
//       .then(response => {
//         setIsLoaded(true);
//         setItems(response.data);
//       })
//       .catch(error => {
//         setIsLoaded(true);
//         setError(error);
//       });
//     }

//   },[subChapterCode,chapterCode,courseCode]);

//   useEffect(() => {
//     if (questionRef.current) {
//       const questionWidth = questionRef.current.getBoundingClientRect().width;
//       const optionStyle = {
//         width: questionWidth + 'px',
//       };
//       setOptionStyle(optionStyle);
//     }
//   }, [items]);

//   const mergedItems = items.reduce((acc, item) => {
//     if (!acc[item.id]) {
//       acc[item.id] = item;
//     } else {
//       acc[item.id] = { ...acc[item.id], ...item };
//     }
//     return acc;
//   }, {});

//   const mergedItemsArray = Object.values(mergedItems);

//   const handleOptionClick = (itemId, option) => {
//     // Check if the option is already selected for the given question
//     if (!selectedAnswers[itemId] && !showResultButton) {
//       setSelectedAnswers(prevState => ({
//         ...prevState,
//         [itemId]: option.toString(), // Convert the option to a string
//       }));
//     }
//   };

//   const getOptionClassName = (itemId, option) => {
//     const item = mergedItemsArray.find(item => item.id === itemId);
//     const selectedOption = selectedAnswers[itemId];

//     if (selectedOption === option.toString()) {
//       if (showResultButton) {
//         if (selectedOption !== item.correctOpt.toString()) {
//           return 'option wrong';
//         }
//       }

//       return 'option selected';
//     } else if (disabledQuestions.includes(itemId)) {
//       // Disable the option if the question is disabled
//       return 'option disabled';
//     } else {
//       return 'option';
//     }
//   };

//   const handleShowResultClick = async () => {



//     const correctCount = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
//       const item = mergedItemsArray[index];
//       if (selectedOption === item.correctOpt.toString()) {
//         acc++;
//       }
//       return acc;
//     }, 0);

//     Swal.fire({
//       title: 'Result',
//       text: `You answered ${correctCount} questions correctly out of ${items.length}!`,
//       icon: 'info',
//     });

//     const totalScore = items.length;
//     const userScore = correctCount;
//     setShowResultDetails(true);

//     try {
//       const response = await axios.post(
//         `https://nec.geoneer.com.np/api/exam/courses/SO-PSC/chapters/${chapterCode}/subchapters/${subChapterCode}/results/`,
//         {
//           user: user.id,
//           course: courseId,
//           chapter: chapterId,
//           subChapter: subChapterId,
//           score: userScore.toFixed(2),
//           total_score: totalScore.toFixed(2),
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${authTokens?.access}`,
//           },
//         }
//       );
//       setSubmissionResponse(response.data);
//       Swal.fire('Success', 'Quiz results submitted successfully!', 'success');
//     } catch (error) {
//       setSubmissionResponse('Failed to submit quiz results.');
//       Swal.fire('Error', 'Failed to submit quiz results.', 'error');
//     }
//   };
//   const attendedQuestionsCount = Object.keys(selectedAnswers).length;

//   return (
//     <div className="quiz-container">
      
    
//       <h6>Practice Questions </h6>
//       <br></br>

      

//       <div className="attendance-count">{attendedQuestionsCount} Questions Attended</div>

//       {error && <div>Error: {error.message}</div>}

//       {!isLoaded && !error && <div>Loading...</div>}

//       {isLoaded && mergedItemsArray.length === 0 && <div>No items to display</div>}

//       {isLoaded && mergedItemsArray.length > 0 && (
//    <ul style={{ listStyleType: 'none', paddingLeft: '0', marginRight: '10px' }}>
//           {mergedItemsArray.map(item => (
//             <li key={item.id}>
//               <br></br>
// <h6>
//   <div ref={questionRef} style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//     <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
//       Q {questionNumber++}:
//     </span>
//     <span style={{ flex: 1 }}>
//       {parse(item.question)}
//     </span>
//   </div>
// </h6>

// <ul style={{ listStyleType: 'none', padding: '0', margin: '0', textAlign: 'left' }}>
//   <li
//     className={getOptionClassName(item.id, "1")}
//     onClick={() => handleOptionClick(item.id, "1")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option1)}</span>
//   </li>
//   <li
//     className={getOptionClassName(item.id, "2")}
//     onClick={() => handleOptionClick(item.id, "2")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option2)}</span>
//   </li>
//   <li
//     className={getOptionClassName(item.id, "3")}
//     onClick={() => handleOptionClick(item.id, "3")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option3)}</span>
//   </li>
//   <li
//     className={getOptionClassName(item.id, "4")}
//     onClick={() => handleOptionClick(item.id, "4")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option4)}</span>
//   </li>
// </ul>
// {showResultDetails && (
//                   <div className="result-details">
//                   <p>Correct Option: {item.correctOpt}</p>
//                   <div className="result-details"  style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//   <p >Explanation: {parse(item.explanation)}</p>
// </div>
//                 </div>
// )}

           
//             </li>
//           ))}
//         </ul>
//       )}

//       {attendedQuestionsCount === mergedItemsArray.length && !showResultButton && (
//         <button className="submit-button" onClick={handleResultSubmit}>Submit</button>
//       )}

//       {showResultButton && (
//         <button className="show-result-button" onClick={handleShowResultClick}>Show Result</button>
//       )}


//       {console.log(submissionResponse)}
//     </div>
//   );
// };

// export default SubChapterWiseExam;


// import React, { useState, useEffect, useRef,useContext} from 'react';
// import axios from 'axios';
// import '../../components/quiz.css';
// import Swal from 'sweetalert2';
// import parse from 'html-react-parser';
// import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import AuthContext from '../../context/AuthContext';



// const SubChapterWiseExam = (props) => {
//   const [error, setError] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [items, setItems] = useState([]);
//   const [selectedAnswers, setSelectedAnswers] = useState({});
//   const questionRef = useRef(null);
//   const [showResultButton, setShowResultButton] = useState(false);
//   const [optionStyle, setOptionStyle] = useState({});
//   const [showResultDetails, setShowResultDetails] = useState(false);
//   const [disabledQuestions, setDisabledQuestions] = useState([]);
//   let questionNumber = 1; 
//   const location = useLocation();

//   const { authTokens, user} = useContext(AuthContext);

//   const [submissionResponse, setSubmissionResponse] = useState(null);

//   const chapterId = location.state?.chapterId;
//   const chapterCode = location.state?.chapterCode;
//   const courseCode = location.state?.courseCode;
//   // const chapterName = location.state?.chapterName;
//   const courseId = location.state?.courseId

//   const subChapterCode = location.state?.subChapterCode;
//     const subChapterId = location.state?.subChapterId;

//   const handleResultSubmit = (e) => {
//     e.preventDefault(); // Prevent default form submission
//     setShowResultButton(true);
//     setDisabledQuestions(Object.keys(selectedAnswers));
//   };

//   useEffect(() => {
//     const handleBeforeUnload = (e) => {
//       if (!showResultButton) {
//         e.preventDefault();
//         e.returnValue = '';
//       }
//     };
  
//     window.addEventListener('beforeunload', handleBeforeUnload);
  
//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [showResultButton]);
  

//   useEffect(() => {
//     axios
//       .get(` https://nec.geoneer.com.np/api/exam/questions/${courseCode}/${chapterCode}/${subChapterCode} `)
//       .then(response => {
//         setIsLoaded(true);
//         setItems(response.data);
//       })
//       .catch(error => {
//         setIsLoaded(true);
//         setError(error);
//       });
//   },[courseCode,chapterCode,subChapterCode]);

//   useEffect(() => {
//     if (questionRef.current) {
//       const questionWidth = questionRef.current.getBoundingClientRect().width;
//       const optionStyle = {
//         width: questionWidth + 'px',
//       };
//       setOptionStyle(optionStyle);
//     }
//   }, [items]);

//   const mergedItems = items.reduce((acc, item) => {
//     if (!acc[item.id]) {
//       acc[item.id] = item;
//     } else {
//       acc[item.id] = { ...acc[item.id], ...item };
//     }
//     return acc;
//   }, {});

//   const mergedItemsArray = Object.values(mergedItems);

//   const handleOptionClick = (itemId, option) => {
//     // Check if the option is already selected for the given question
//     if (!selectedAnswers[itemId] && !showResultButton) {
//       setSelectedAnswers(prevState => ({
//         ...prevState,
//         [itemId]: option.toString(), // Convert the option to a string
//       }));
//     }
//   };

//   const getOptionClassName = (itemId, option) => {
//     const item = mergedItemsArray.find(item => item.id === itemId);
//     const selectedOption = selectedAnswers[itemId];

//     if (selectedOption === option.toString()) {
//       if (showResultButton) {
//         if (selectedOption !== item.correctOpt.toString()) {
//           return 'option wrong';
//         }
//       }

//       return 'option selected';
//     } else if (disabledQuestions.includes(itemId)) {
//       // Disable the option if the question is disabled
//       return 'option disabled';
//     } else {
//       return 'option';
//     }


  
//   };

//   // const handleShowResultClick = () => {
//   //   const count = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
//   //     const item = mergedItemsArray[index];
//   //     if (selectedOption === item.correctOpt.toString()) {
//   //       acc++;
//   //     }
//   //     return acc;
//   //   }, 0);

//   //   setShowResultDetails(true);

//   //   Swal.fire({
//   //     title: 'Result',
//   //     text: `You answered ${count} questions correctly!`,
//   //     icon: 'info',
//   //   }).then(() => {
//   //     // Store quiz data in local storage
//   //     const quizData = {
//   //       date: new Date().toLocaleString(),
//   //       score: count,
//   //     };
//   //     localStorage.setItem('quizData', JSON.stringify(quizData));
//   //   });
//   // };


//   const handleShowResultClick = async () => {
//     // const correctCount = Object.values(selectedAnswers).reduce(
//     //   (acc, selectedOption, index) => {
//     //     const item = items[index];
//     //     if (selectedOption === item.correctOpt.toString()) {
//     //       acc++;
      
//     //     }
//     //     return acc;
//     //   },
//     //   0
//     // );

//     console.log('Items:', items);
//     console.log('Selected Answers:', selectedAnswers);


//     // const correctCount = Object.values(selectedAnswers).reduce(
//     //   (acc, selectedOption, index) => {
//     //     const item = items[index];
    
//     //     // Ensure item exists and selectedOption is valid
//     //     if (item && selectedOption && selectedOption === item.correctOpt.toString()) {
//     //       acc++;
//     //     }
//     //     return acc;
//     //   },
//     //   0
//     // );
    
//     // const correctCount = Object.values(selectedAnswers).reduce(
//     //   (acc, selectedOption, index) => {
//     //     const item = items[index];
//     //     console.log(`Step ${index + 1}:`);
//     //     console.log('Current Accumulator (acc):', acc);
//     //     console.log('Selected Option:', selectedOption);
//     //     console.log('Correct Option:', item.correctOpt);
    
//     //     if (selectedOption === item.correctOpt.toString()) {
//     //       acc++;
//     //       console.log('✅ Correct Answer! Incrementing acc.');
//     //     } else {
//     //       console.log('❌ Incorrect Answer! acc remains the same.');
//     //     }
//     //     console.log('Updated Accumulator (acc):', acc);
//     //     console.log('--------------------');
//     //     return acc;
//     //   },
//     //   0
//     // );


//     const correctCount = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
//       const item = mergedItemsArray[index];
//       if (selectedOption === item.correctOpt.toString()) {
//         acc++;
//       }
//       return acc;
//     }, 0);
    

//     Swal.fire({
//       title: 'Result',
//       text: `You answered ${correctCount} questions correctly out of ${items.length}!`,
//       icon: 'info',
//     });

//     const totalScore = items.length;
//     const userScore = correctCount;
//     setShowResultDetails(true);

//     try {
//       const response = await axios.post(
//         `https://nec.geoneer.com.np/api/exam/courses/${courseCode}/chapters/${chapterCode}/subchapters/${subChapterCode}/results/`,
//         {
//           user: user.id,
//           course: courseId,
//           chapter: chapterId,
//           subChapter: subChapterId,
//           score: userScore.toFixed(2),
//           total_score: totalScore.toFixed(2),
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${authTokens?.access}`,
//           },
//         }
//       );
//       setSubmissionResponse(response.data);
//       Swal.fire('Success', 'Quiz results submitted successfully!', 'success');
//     } catch (error) {
//       setSubmissionResponse('Failed to submit quiz results.');
//       Swal.fire('Error', 'Failed to submit quiz results.', 'error');
//     }
//   };

//   const attendedQuestionsCount = Object.keys(selectedAnswers).length;

//   return (
//     <div className="quiz-container">
      
    
//       {/* <h6>Practice Questions for {chapterName}   <strong></strong> </h6> */}
//       {/* {console.log("course id is ",courseId)} */}
      
    
//       <br></br>

      

//       <div className="attendance-count">{attendedQuestionsCount} Questions Attended</div>

//       {error && <div>Error: {error.message}</div>}

//       {!isLoaded && !error && <div>Loading...</div>}

//       {isLoaded && mergedItemsArray.length === 0 && <div>No items to display</div>}

//       {isLoaded && mergedItemsArray.length > 0 && (
//    <ul style={{ listStyleType: 'none', paddingLeft: '0', marginRight: '10px' }}>
//           {mergedItemsArray.map(item => (
//             <li key={item.id}>
//               <br></br>
// <h6>
//   <div ref={questionRef} style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//     <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
//       Q {questionNumber++}:
//     </span>
//     <span style={{ flex: 1 }}>
//       {parse(item.question)}
//     </span>
//   </div>
// </h6>

// <ul style={{ listStyleType: 'none', padding: '0', margin: '0', textAlign: 'left' }}>
//   <li
//     className={getOptionClassName(item.id, "1")}
//     onClick={() => handleOptionClick(item.id, "1")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option1)}</span>
//   </li>
//   <li
//     className={getOptionClassName(item.id, "2")}
//     onClick={() => handleOptionClick(item.id, "2")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option2)}</span>
//   </li>
//   <li
//     className={getOptionClassName(item.id, "3")}
//     onClick={() => handleOptionClick(item.id, "3")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option3)}</span>
//   </li>
//   <li
//     className={getOptionClassName(item.id, "4")}
//     onClick={() => handleOptionClick(item.id, "4")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option4)}</span>
//   </li>
// </ul>
// {showResultDetails && (
//                   <div className="result-details">
//                   <p>Correct Option: {item.correctOpt}</p>
//                   <div className="result-details"  style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//   <p >Explanation: {parse(item.explanation)}</p>
// </div>
//                 </div>
// )}

           
//             </li>
//           ))}
//         </ul>
//       )}

//       {attendedQuestionsCount === mergedItemsArray.length && !showResultButton && (
//         <button className="submit-button" onClick={handleResultSubmit}>Submit</button>
//       )}

//       {showResultButton && (
//         <button className="show-result-button" onClick={handleShowResultClick}>Show Result</button>
//       )}

// {console.log(submissionResponse)}
//     </div>
//   );
// };

// export default SubChapterWiseExam;

import { useState, useEffect, useRef, useContext } from "react"
import axios from "axios"
import "../../components/quiz.css"
import Swal from "sweetalert2"
import parse from "html-react-parser"
import { useLocation } from "react-router-dom"
import AuthContext from "../../context/AuthContext"
import ReportQuestion from "./ReportQuestion"
import { AlertTriangle } from "lucide-react"

const SubChapterWiseExam = () => {
  const [mergedItemsArray, setMergedItemsArray] = useState([])
  const [selectedAnswers, setSelectedAnswers] = useState({})
  const [showResultButton, setShowResultButton] = useState(false)
  const [showResultDetails, setShowResultDetails] = useState(false)
  const [disabledQuestions, setDisabledQuestions] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [submissionResponse, setSubmissionResponse] = useState(null)
  const [reportModalOpen, setReportModalOpen] = useState(false)
  const [reportQuestionId, setReportQuestionId] = useState(null)

  const questionRef = useRef(null)
  const location = useLocation()
  const { authTokens, user } = useContext(AuthContext)

  const { courseId, courseCode, chapterId, chapterCode, subChapterId, subChapterCode } = location.state || {}

  useEffect(() => {
    axios
      .get(`https://nec.geoneer.com.np/api/exam/questions/${courseCode}/${chapterCode}/${subChapterCode}`)
      .then((response) => {
        setIsLoaded(true)
        setMergedItemsArray(response.data)
      })
      .catch((error) => {
        setIsLoaded(true)
        setError(error)
      })
  }, [courseCode, chapterCode, subChapterCode])

  const handleOptionClick = (itemId, option) => {
    if (!selectedAnswers[itemId] && !showResultButton) {
      setSelectedAnswers((prevState) => ({
        ...prevState,
        [itemId]: option.toString(),
      }))
    }
  }

  const getOptionClassName = (itemId, option) => {
    const item = mergedItemsArray.find((item) => item.id === itemId)
    const selectedOption = selectedAnswers[itemId]

    if (selectedOption === option.toString()) {
      if (showResultButton) {
        if (selectedOption !== item.correctOpt.toString()) {
          return "option wrong"
        }
      }
      return "option selected"
    } else if (disabledQuestions.includes(itemId)) {
      return "option disabled"
    } else {
      return "option"
    }
  }

  const handleResultSubmit = (e) => {
    e.preventDefault()
    setShowResultButton(true)
    setDisabledQuestions(Object.keys(selectedAnswers))
  }

  const handleShowResultClick = async () => {
    const correctCount = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
      const item = mergedItemsArray[index]
      if (selectedOption === item.correctOpt.toString()) {
        acc++
      }
      return acc
    }, 0)

    setShowResultDetails(true)

    Swal.fire({
      title: "Result",
      text: `You answered ${correctCount} questions correctly out of ${mergedItemsArray.length}!`,
      icon: "info",
    })

    try {
      const response = await axios.post(
        `https://nec.geoneer.com.np/api/exam/courses/${courseCode}/chapters/${chapterCode}/subchapters/${subChapterCode}/results/`,
        {
          user: user.id,
          course: courseId,
          chapter: chapterId,
          subChapter: subChapterId,
          score: correctCount,
          total_score: mergedItemsArray.length,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens?.access}`,
          },
        },
      )
      setSubmissionResponse(response.data)
      Swal.fire("Success", "Quiz results submitted successfully!", "success")
    } catch (error) {
      setSubmissionResponse("Failed to submit quiz results.")
      Swal.fire("Error", "Failed to submit quiz results.", "error")
    }
  }

  const handleReportQuestion = (questionId) => {
    setReportQuestionId(questionId)
    setReportModalOpen(true)
  }

  if (error) {
    return <div>Error: {error.message}</div>
  } else if (!isLoaded) {
    return <div>Loading...</div>
  } else {
    return (
      <div className="quiz-container">
        <div className="attendance-count">{Object.keys(selectedAnswers).length} Questions Attended</div>

        {mergedItemsArray.length === 0 ? (
          <div>No items to display</div>
        ) : (
          <ul style={{ listStyleType: "none", paddingLeft: "0", marginRight: "10px" }}>
            {mergedItemsArray.map((item, index) => (
              <li key={item.id}>
                <br />
                <div className="flex justify-between items-start">
                  <h6>
                    <div ref={questionRef} style={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}>
                      <span style={{ marginRight: "10px", whiteSpace: "nowrap" }}>Q {index + 1}:</span>
                      <span style={{ flex: 1 }}>{parse(item.question)}</span>
                    </div>
                  </h6>
                  <button
                    onClick={() => handleReportQuestion(item.id)}
                    className="ml-2 px-2 py-1 text-sm bg-gray-200 hover:bg-gray-300 rounded"
                  >
                    <AlertTriangle className="w-4 h-4 mr-1 inline" />
                    Report
                  </button>
                </div>

                <ul style={{ listStyleType: "none", padding: "0", margin: "0", textAlign: "left" }}>
                  {["1", "2", "3", "4"].map((optionNumber) => (
                    <li
                      key={optionNumber}
                      className={getOptionClassName(item.id, optionNumber)}
                      onClick={() => handleOptionClick(item.id, optionNumber)}
                      style={{ marginBottom: "5px", padding: "10px", cursor: "pointer" }}
                    >
                      <span className="option-text">{parse(item[`option${optionNumber}`])}</span>
                    </li>
                  ))}
                </ul>

                {showResultDetails && (
                  <div className="result-details">
                    <p>Correct Option: {item.correctOpt}</p>
                    <div
                      className="result-details"
                      style={{ display: "flex", alignItems: "flex-start", textAlign: "left" }}
                    >
                      <p>Explanation: {parse(item.explanation)}</p>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}

        {Object.keys(selectedAnswers).length === mergedItemsArray.length && !showResultButton && (
          <button className="submit-button" onClick={handleResultSubmit}>
            Submit
          </button>
        )}

        {showResultButton && (
          <button className="show-result-button" onClick={handleShowResultClick}>
            Show Result
          </button>
        )}

        <ReportQuestion
          questionId={reportQuestionId}
          isOpen={reportModalOpen}
          onClose={() => setReportModalOpen(false)}
        />
        {console.log(submissionResponse)}

      </div>
    )
  }
}

export default SubChapterWiseExam

