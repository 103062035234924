
import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


const WeeklyTestRanking = (props) => {
  const { authTokens, user } = useContext(AuthContext);
  const [rankingResults, setRankingResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const id = location.state?.id;
  const testname = location.state?.testname;
  const testdate = location.state?.testdate;


  useEffect(() => {
    const fetchRankingResults = async () => {
      try {
        const response = await axios.get(
          `https://nec.geoneer.com.np/api/exam/weekly-test-ranking/?test_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${authTokens?.access}`,
            },
          }
        );
        setRankingResults(response.data);
        setLoading(false);
        console.log(response.data);
      } catch (err) {
        console.error("Error fetching ranking results:", err);
        setError("Failed to load ranking results.");
        setLoading(false);
      }
    };

    if (authTokens && user) {
      fetchRankingResults();
    }
  }, [authTokens, user,id]);

  if (!authTokens) {
    return <p>Please log in to view the test rankings.</p>;
  }

  if (loading) {
    return <p>Loading ranking results...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container mt-4">
      <br />
      <br />
      <h3 className="text-center mb-4">Weekly Test Rankings</h3>
      <h5 className="text-center mb-4">Test Name : {testname}</h5>
      <h5 className="text-center mb-4">Test Date : {testdate}</h5>

      <div className="table-responsive">
        <table className="table table-striped table-hover align-middle">
          <thead className="table-dark">
            <tr>
              <th scope="col">Rank.</th>
              <th scope="col">User</th>
             
              <th scope="col">Score</th>
              <th scope="col">Total Score</th>
            </tr>
          </thead>
          <tbody>
            {rankingResults.length > 0 ? (
              rankingResults.map((result, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{result.first_name.charAt(0).toUpperCase()} {result.last_name}</td>
                 
                  <td>{result.score}</td>
                  <td>100</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No ranking results found for this test.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WeeklyTestRanking;
